import { gql } from '@apollo/client';

export const SEARCH_ACTIVE_MARKETS = gql`
  query SearchActiveMarkets(
    $from: Int!
    $pageSize: Int!
    $sortFilter: String
    $sortOrder: String
    $wineName: String
    $filters: ActiveMarketFilters
  ) {
    searchActiveMarkets(
      from: $from
      pageSize: $pageSize
      sortFilter: $sortFilter
      sortOrder: $sortOrder
      wineName: $wineName
      filters: $filters
    ) {
      total
      results {
        assetId
        productUrn
        highestBid {
          createdDate
          price
        }
        lowestOffer {
          createdDate
          price
        }
        marketValue
        lwin18
        score
        region
        producer
        wineUnitSize
        unitSize
        unitCount
        imageFileName
        vintage
        wineName
        spread
      }
    }
  }
`;
