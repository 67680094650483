import React, { useEffect } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import { useMutation, useSubscription } from '@apollo/client';
import Typography from '../Typography';
import { colours } from '../../theme/onboarding/palette';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import { AssetInstance } from '../TransferWineTable';
import { TRANSFER_CULTX_WINE } from '../../graphql/mutations/holdingTransferWine';
import { TRANSFER_OUT_SUBSCRIPTION } from '../../graphql/subscriptions/getMyTransferOut';
import { cache } from '../../graphql/cache';
import { logError } from '../../utils/logger';

interface TransferOutProcessingProps {
  onError: () => void;
  onSuccess: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    height: 228,
    width: 271,
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(10),
    flexGrow: 1,
  },
  title: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '22px',
    color: colours.darkBlue,
  },
  message: {
    fontSize: '14px',
    color: colours.purpleMid,
    textAlign: 'center',
  },
  green: {
    color: colours.brightGreen,
  },
}));

export default function ProcessModal({ onError, onSuccess }: TransferOutProcessingProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state } = useSelectedRowsContext();
  const selectedRows = state.selectedTransferedRows;
  const assetInstances = selectedRows.filter((x) => x.type === 'AssetInstance') as AssetInstance[];
  const [requestTransfer] = useMutation(TRANSFER_CULTX_WINE, {
    // Catches network errors and returns them in errors in response
    onError: () => null,
  });
  const { data: transferOutDataSubscription, error: transferOutDataSubscriptionError } =
    useSubscription(TRANSFER_OUT_SUBSCRIPTION);

  useEffect(() => {
    const transferCultWineRequest = async () => {
      const { errors, data: requestTransferData } = await requestTransfer({
        variables: {
          assetUnitIds: assetInstances.map((e) => e.id.toString()),
        },
      });

      if (requestTransferData?.transferInventoryOut.length) {
        cache.evict({ fieldName: 'productAssetInstances' });
        cache.evict({ fieldName: 'holdingsCurrent' });
        onSuccess();
      }

      if (errors) {
        logError({
          originalError: errors[0],
          error: new Error('Transfer assets out failed'),
          filename: 'ProcessModal',
          tags: { userFlow: 'transfer' },
          additionalInfo: {
            errors: JSON.stringify(errors),
            selectedRows: JSON.stringify(selectedRows),
            assetUnitIds: JSON.stringify(assetInstances.map((ai) => ai.id)),
          },
        });
        onError();
      }
    };

    transferCultWineRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (transferOutDataSubscriptionError) {
      logError({
        originalError: transferOutDataSubscriptionError,
        error: new Error('Subscription getMyTransferOutRequest failed'),
        filename: 'ProcessModal',
        tags: { userFlow: 'transfer' },
      });
      onError();
      return;
    }

    if (transferOutDataSubscription) {
      cache.evict({ fieldName: 'productAssetInstances' });
      cache.evict({ fieldName: 'holdingsCurrent' });
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferOutDataSubscription, transferOutDataSubscriptionError]);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <Typography className={clsx(classes.title)}>{t('portfolio:transfer.processing.title')}</Typography>
        <CircularProgress classes={{ circle: classes.green }} />
        <Typography className={clsx(classes.message)}>{t('portfolio:transfer.processing.message')}</Typography>
      </div>
    </div>
  );
}
