import React, {
  Dispatch,
  FC,
  forwardRef,
  ReactNode,
  Ref,
  SetStateAction,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import InfiniteScrollDropdown from '../Dropdown/InfiniteScrollDropdown';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import Typography from '../Typography';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

interface IFilterViewViewtProps1 {
  sourceData: Record<string, string[]>;
  customFilters: ServerSideFiltersType;
  setFilteredSource?: (isFiltered: boolean, customFilters: ServerSideFiltersType) => void;
  isLoading?: boolean;
  isVisible?: boolean;
}

export interface ServerSideFilterConfigType {
  paramsCheckRan?: boolean;
  isFiltered?: boolean;
  showSortResult: boolean;
  filters: ServerSideFiltersType;
}

export type ServerSideFiltersType = Record<
  string,
  {
    label: string;
    selected: string[];
    previewSelected: string[];
    itemsContainerClassName?: string;
    className?: string;
    isNumerical?: boolean;
    customFilterFunction?: (
      customFilters: ServerSideFiltersType,
      sourceData: Record<string, string[]>,
    ) => Record<string, string[]>;
    customItems?: string[];
    isAsc?: boolean;
    customSortOrder?: string[];
    selectAllText?: string;
    searchText?: string;
    isSearchable?: boolean;
    isHidden?: boolean;
    onShowResults?: (customFilters?: ServerSideFiltersType) => void;
  }
>;

export interface ServerSideFilterViewRef {
  resetFilters: () => void;
  getFilters: () => ServerSideFiltersType | null;
  setFiltersFunc: (filters: ServerSideFiltersType | null) => void;
}

interface CheckBoxProps {
  id?: string;
  isChecked: boolean;
  children?: ReactNode;
  className?: string;
  inputClassName?: string;
  onChange?: (id: string, checked: boolean) => void;
  isDisabled?: boolean;
}

const CheckBox: FC<CheckBoxProps> = ({
  children,
  className,
  inputClassName,
  onChange,
  id,
  isChecked = false,
  isDisabled = false,
}) => {
  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled && onChange) onChange(id || '', e.target.checked);
  };

  return (
    <div className={`flex items-center ${className || ''}`.trim()}>
      <input
        disabled={isDisabled}
        type="checkbox"
        checked={isChecked}
        onChange={onCheck}
        className={`border-gray-300 rounded h-5 w-5 ${inputClassName || ''}`.trim()}
      />
      {children || null}
    </div>
  );
};

const ServersFilter = forwardRef(
  (
    { sourceData, customFilters, setFilteredSource, isLoading, isVisible = true }: IFilterViewViewtProps1,
    ref: Ref<ServerSideFilterViewRef>,
  ) => {
    const { t } = useTranslation();

    const [filters, setFilters] = useState<ServerSideFiltersType>(customFilters);

    const generateDropDownItems = useCallback(
      (
        source: Record<string, string[]>,
        filterKey: string,
        filterData: ServerSideFiltersType,
        setFilterData: Dispatch<SetStateAction<ServerSideFiltersType>>,
      ) => {
        const currentFilter = filterData[filterKey];
        const selectAll = currentFilter.selectAllText ?? `${t('common:filters.all')} ${currentFilter.label}`;

        let uniqueItems: string[] = [];
        if (!currentFilter.customItems?.length) {
          uniqueItems = [
            selectAll,
            ...(source?.[filterKey] ?? []).sort((a, b) => {
              if (currentFilter.customSortOrder) {
                return (
                  currentFilter.customSortOrder.indexOf(String(a)) - currentFilter.customSortOrder.indexOf(String(b))
                );
              }

              if (currentFilter.isNumerical) {
                if (a === '') return 1;
                if (b === '') return -1;
                return currentFilter.isAsc
                  ? parseFloat(String(a)) - parseFloat(String(b))
                  : parseFloat(String(b)) - parseFloat(String(a));
              }

              return currentFilter.isAsc
                ? String(b).toLowerCase().localeCompare(String(a).toLowerCase())
                : String(a).toLowerCase().localeCompare(String(b).toLowerCase());
            }),
          ].filter((x) => x);
        } else {
          uniqueItems = [selectAll, ...currentFilter.customItems];
        }

        const selectValue = (value: string) => {
          if (value === selectAll && currentFilter.previewSelected.includes(selectAll)) {
            // add all values
            setFilterData((prev) => {
              return {
                ...prev,
                [filterKey]: {
                  ...prev[filterKey],
                  label: currentFilter.label,
                  previewSelected: [],
                },
              };
            });

            return;
          }

          if (value === selectAll && !currentFilter.previewSelected.includes(selectAll)) {
            // add all values
            setFilterData((prev) => {
              return {
                ...prev,
                [filterKey]: {
                  ...prev[filterKey],
                  label: currentFilter.label,
                  previewSelected: uniqueItems,
                },
              };
            });

            return;
          }

          if (
            currentFilter.previewSelected.length + 1 !== uniqueItems.length - 1 &&
            currentFilter.previewSelected.includes(selectAll) &&
            currentFilter.previewSelected.includes(value)
          ) {
            // remove select all
            setFilterData((prev) => {
              return {
                ...prev,
                [filterKey]: {
                  ...prev[filterKey],
                  label: currentFilter.label,
                  previewSelected: currentFilter.previewSelected
                    .filter((x) => x !== selectAll)
                    .filter((x) => x !== value),
                },
              };
            });
            return;
          }

          if (
            currentFilter.previewSelected.length + 1 === uniqueItems.length - 1 &&
            !currentFilter.previewSelected.includes(selectAll) &&
            !currentFilter.previewSelected.includes(value)
          ) {
            // select all
            setFilterData((prev) => {
              return {
                ...prev,
                [filterKey]: {
                  ...prev[filterKey],
                  label: currentFilter.label,
                  previewSelected: [...prev[filterKey].previewSelected, selectAll, value],
                },
              };
            });
            return;
          }

          if (currentFilter.previewSelected.includes(value)) {
            // remove value
            setFilterData((prev) => {
              return {
                ...prev,
                [filterKey]: {
                  ...prev[filterKey],
                  label: currentFilter.label,
                  previewSelected: currentFilter.previewSelected.filter((x) => x !== value),
                },
              };
            });

            return;
          }

          if (!currentFilter.previewSelected.includes(value)) {
            // add value
            setFilterData((prev) => {
              return {
                ...prev,
                [filterKey]: {
                  ...prev[filterKey],
                  label: currentFilter.label,
                  previewSelected: [...prev[filterKey].previewSelected, value],
                },
              };
            });
          }
        };

        return uniqueItems.length > 1
          ? uniqueItems.map((value, index) => {
              const values = String(value || '');
              return {
                id: `${index}${value}`,
                content: (
                  <button
                    type="button"
                    className="tw-w-full tw-py-3 tw-px-4 tw-text-base tw-flex tw-border-none tw-bg-white tw-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      selectValue(values);
                    }}
                  >
                    <CheckBox
                      inputClassName="tw-max-h-[18px] tw-max-w-[18px] tw-min-h-[18px] tw-min-w-[18px]"
                      isChecked={currentFilter.previewSelected.includes(values)}
                      id={`${value}`}
                    />
                    <Typography className="tw-ml-3 tw-text-left tw-text-black tw-text-14 ">
                      {capitalizeFirstLetter(values)}
                    </Typography>
                  </button>
                ),
                value: `${values}`,
              };
            })
          : [];
      },
      [t],
    );
    const isSetFiltered = (filterConfig: ServerSideFiltersType | null) => {
      let isFiltered = false;
      if (!filterConfig) return false;
      Object.keys(filterConfig).forEach((key) => {
        if (filterConfig[key].previewSelected.length > 0 && !isFiltered) isFiltered = true;
      });

      return isFiltered;
    };

    const applyFilters = (filterConfig: ServerSideFiltersType | null, isClearFilterKey?: string | null) => {
      if (!filterConfig) return;

      const filterChange = { ...filterConfig } as unknown as Record<string, Record<string, unknown>>;
      const clearKey = isClearFilterKey as string;
      const isFiltered = isSetFiltered(filterConfig);

      if (clearKey) {
        filterChange[clearKey] = {
          ...filterChange[clearKey],
          label: filterChange[clearKey]?.label,
          selected: [],
          previewSelected: [],
          searchText: '',
        };

        setFilters(filterChange as ServerSideFiltersType);
        setFilteredSource?.(
          isSetFiltered(filterChange as unknown as ServerSideFiltersType),
          filterChange as ServerSideFiltersType,
        );
        return;
      }

      Object.keys(filterConfig).forEach((key) => {
        filterChange[key] = {
          ...filterChange[key],
          label: filterChange[key]?.label,
          selected: filterChange[key]?.previewSelected,
          searchText: '',
        };

        setFilters(filterChange as ServerSideFiltersType);
      });

      setFilteredSource?.(isFiltered, filterChange as ServerSideFiltersType);
    };

    const isApplyBtnDisabled = (customerkey: string | null, customFilter: ServerSideFiltersType | null) => {
      const key = customerkey as string;
      if (!customFilter || !key) return true;
      const seleted = customFilter[key].selected.toString();
      const previewSelected = customFilter[key].previewSelected.toString();
      if (seleted === previewSelected) return true;
      return false;
    };

    const dropDowns = useMemo(() => {
      if (!filters) return [];

      const dropDownkeys = Object.keys(filters);

      return dropDownkeys
        .filter((x) => !filters[x].isHidden)
        .map((key) => {
          const config = filters[key];
          const select = `${t('common:filters.all')} ${config.label}`;
          let value = '';

          if (config.label) {
            value = config.label;
          } else if (config.previewSelected.length > 1) {
            value = `${"t('trade:common.multipleSelection')"}`;
          } else {
            value = config.previewSelected.toString();
          }
          return (
            <div className="" key={config.label}>
              <InfiniteScrollDropdown
                header={
                  config.isSearchable && (
                    <div
                      role="button"
                      tabIndex={0}
                      className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-4 tw-bg-white"
                      onClick={(e) => e.stopPropagation()}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.stopPropagation();
                        }
                      }}
                    >
                      <SearchIcon />
                      <input
                        className="tw-outline-0 tw-outline-none tw-flex-1 tw-h-8 tw-border-none"
                        type="text"
                        name="Search..."
                        placeholder={`${t('common:search')}...`}
                        onChange={(e) => {
                          setFilters((prev) => {
                            return prev
                              ? {
                                  ...prev,
                                  [key]: {
                                    ...prev[key],
                                    searchText: e.target.value,
                                  },
                                }
                              : {};
                          });
                        }}
                        value={config?.searchText}
                      />
                    </div>
                  )
                }
                iconColor="#150036"
                isActive={config.selected.length > 0}
                id={config.label}
                autoClose={false}
                placeholder={select}
                value={value}
                iconClassName="tw-min-w-[10px] tw-w-[10px]"
                containerClassName="tw-rounded"
                itemsContainerClassName={`tw-overflow-y-auto  ${
                  config?.itemsContainerClassName ?? 'tw-max-h-[280px] tw-min-w-[280px]'
                } `}
                className={`tw-flex tw-flex-row tw-text-xs   tw-text-[#150036] tw-font-bold  tw-border-[1px] tw-border-black tw-rounded-2xl  tw-px-3 tw-min-h-[25px] tw-whitespace-nowrap tw-justify-center tw-items-center ${
                  config?.className ?? ''
                }`}
                itemsWrapperClassName="tw-min-w-[280px]"
                items={
                  config.isSearchable && config.searchText
                    ? (generateDropDownItems(sourceData, key, filters, setFilters) || []).filter((x) =>
                        x.value?.toLowerCase().includes(config?.searchText?.toLowerCase() || ''),
                      )
                    : generateDropDownItems(sourceData, key, filters, setFilters) || []
                }
                itemClassName="tw-text-base tw-flex tw-pl-0 tw-pr-0 tw-pt-0 tw-pb-0"
                footer={
                  <div className="tw-flex tw-flex-col tw-p-2 tw-gap-2 tw-bg-white">
                    <Button
                      disabled={!config.previewSelected.length || isApplyBtnDisabled(key, filters)}
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={() => applyFilters(filters)}
                    >
                      Show results
                    </Button>

                    <Button
                      color="inherit"
                      variant="contained"
                      size="medium"
                      onClick={() => applyFilters(filters, key)}
                    >
                      {capitalizeFirstLetter(t('common:filters.clearFilters'))}
                    </Button>
                  </div>
                }
              />
            </div>
          );
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, sourceData, t]);

    // useEffect(() => {
    //   if (filters && !isOpen) {
    //     Object.keys(filters).forEach((key) => {
    //       setFilters((prev) => {
    //         return prev
    //           ? {
    //               ...prev,
    //               [key]: {
    //                 ...prev[key],
    //                 selected: prev[key].selected,
    //                 label: filters[key].label,
    //                 previewSelected: prev[key].selected,
    //               },
    //             }
    //           : null;
    //       });
    //     });
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isOpen, setFilters]);

    const clearAllFilters = (filterConfig: ServerSideFiltersType | null) => {
      if (!filterConfig) return;
      const filterChange = { ...filterConfig } as unknown as Record<string, Record<string, unknown>>;
      Object.keys(filterConfig).forEach((key) => {
        filterChange[key] = {
          ...filterChange[key],
          label: filterChange[key]?.label,
          selected: [],
          previewSelected: [],
          searchText: '',
        };
        setFilters({ ...filterChange } as ServerSideFiltersType);
      });

      setFilteredSource?.(false, filterChange as ServerSideFiltersType);
    };

    useImperativeHandle(
      ref,
      () => ({
        resetFilters: () => {
          clearAllFilters(filters);
        },
        getFilters: () => {
          return filters;
        },
        setFiltersFunc: (filtersIn) => {
          applyFilters(filtersIn);
        },
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filters],
    );

    if (!isVisible) return null;
    return (
      <div className="tw-flex tw-flex-row tw-gap-2 tw-flex-1 tw-flex-wrap ">
        {dropDowns.map((x) =>
          isLoading ? (
            <div
              key={x.key}
              className="tw-border-black tw-rounded-2xl tw-w-[100px] tw-px-3 tw-min-h-[25px] tw-bg-gray-300  tw-animate-pulse"
            />
          ) : (
            x
          ),
        )}{' '}
        <button
          type="button"
          className="tw-underline tw-text-sm tw-border-none tw-outline-none tw-bg-white tw-cursor-pointer tw-text-[#150036]"
          onClick={() => (isLoading ? null : clearAllFilters(filters))}
        >
          {capitalizeFirstLetter(t('common:filters.clear_all_filters'))}
        </button>
      </div>
    );
  },
);
export default ServersFilter;
