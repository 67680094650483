/* eslint-disable react/no-array-index-key */
import { useQuery } from '@apollo/client';
import { sort } from '@cultwines/zellar-client-sdk/utils/sort';
import Button from '@mui/material/Button';
import React, { useMemo } from 'react';
import { Namespace, Resources, TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SelectedRowsProvider } from '../../context/selectedRowsContext';
import useTableControls from '../../hooks/useTableControls';
import { formatterWholeNumber } from '../../utils/currencyFormatter';
import { humanReadableDate } from '../../utils/humanReadableDate';
import { massageToNull } from '../../utils/massageToNull';
import Table from '../Table';
import { BasicCell, Headers, Row, UnitSizeCell, WineNameCell } from '../Table/types';
import useStyles from './styles';
import { graphql } from '../../__generated__';
import { GetMyTradesQuery } from '../../__generated__/graphql';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';
import TradeHistoryTable from './Table';
import { RecentTradesFacets, Trade } from './types';
import { getProductUrl } from '../../utils/common';

const GET_MY_RECENT_TRADES = graphql(`
  query GetMyTrades {
    omsGetMyTrades {
      id
      offer {
        userId
        direction
      }
      bid {
        userId
        direction
      }
      price
      tradeTime
      asset {
        id
        unitSize
        unitCount
        vintage {
          id
          vintage
          wine {
            id
            name
            appellation {
              name
            }
            region {
              name
            }
          }
        }
      }
      quantity
      totalPrice
    }

    authUser {
      userId
    }
  }
`);

interface TableData extends Row {
  activity: BasicCell;
  wineName: WineNameCell;
  vintage: BasicCell;
  unitSize: UnitSizeCell;
  tradeDate: BasicCell;
  tradePrice: BasicCell;
}

function selectTrades(rawTrades: GetMyTradesQuery['omsGetMyTrades'], userId: string | undefined): Trade[] {
  return rawTrades.map((rawTrade) => ({
    // the userId on the trade is uppercase, but the userId from the authUser query is lowercase,
    // make them consistent so we get the desired output here.
    activity: rawTrade.bid.userId.toUpperCase() === userId?.toUpperCase() ? 'bid' : 'offer',
    wineName: rawTrade.asset.vintage.wine.name,
    appellation: massageToNull(rawTrade.asset.vintage.wine.appellation?.name),
    region: rawTrade.asset.vintage.wine.region?.name || 'Unknown region',
    tradeDate: rawTrade.tradeTime,
    tradePrice: rawTrade.totalPrice,
    vintage: rawTrade.asset.vintage.vintage,
    unitCount: rawTrade.asset.unitCount,
    unitSize: rawTrade.asset.unitSize,
    assetId: rawTrade.asset.id,
    id: rawTrade.id,
    quantity: rawTrade.quantity,
  }));
}

function createTableData(trades: Trade[], t: TFunction<Namespace<keyof Resources>>): TableData[] {
  return trades.map((trade) => {
    const { wineName, vintage, assetId, unitSize, unitCount } = trade;
    const prodUrl = getProductUrl({ wineName, vintage, assetId, unitSize, unitCount });

    return {
      activity: {
        mainContent:
          trade.activity === 'bid' ? t('portfolio:tradeHistoryTable.bid') : t('portfolio:tradeHistoryTable.offer'),
      },
      wineName: {
        appellation: trade.appellation,
        region: trade.region,
        wineName: trade.wineName,
        link: `${prodUrl}`,
      },
      vintage: {
        mainContent: trade.vintage,
      },
      unitSize: {
        unitCount: trade.unitCount,
        unitSize: trade.unitSize,
      },
      tradeDate: {
        mainContent: humanReadableDate(trade.tradeDate),
      },
      tradePrice: {
        mainContent: formatterWholeNumber.format(trade.tradePrice),
      },
      rowId: trade.id,
    };
  });
}

function RecentTradesTable(): JSX.Element {
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, error, loading } = useQuery(GET_MY_RECENT_TRADES);
  const history = useHistory();
  const { handleSortUpdated, sortDirection, sortFacet } = useTableControls<RecentTradesFacets>({
    defaultFacet: 'tradeDate',
    defaultSortDirection: 'desc',
  });
  const common = {
    onClick: handleSortUpdated,
    direction: sortDirection,
  };
  const headers: Headers<Omit<TableData, 'rowId' | 'actions'>, keyof RecentTradesFacets> = {
    activity: {
      title: t('trade:activity'),
      active: sortFacet === 'activity',
      id: 'activity',
      ...common,
    },
    wineName: {
      title: t('market:table.wineName'),
      active: sortFacet === 'wineName',
      id: 'wineName',
      ...common,
    },
    vintage: { title: t('common:vintage'), active: sortFacet === 'vintage', id: 'vintage', ...common },
    unitSize: { title: t('common:unitSize') },
    tradeDate: { title: t('trade:tradeDate'), active: sortFacet === 'tradeDate', id: 'tradeDate', ...common },
    tradePrice: { title: t('trade:tradePrice'), active: sortFacet === 'tradePrice', id: 'tradePrice', ...common },
  };

  const trades = useMemo(
    () =>
      [...selectTrades(data?.omsGetMyTrades ?? [], data?.authUser.userId)].sort((a, b) =>
        sort(a[sortFacet], b[sortFacet], sortDirection),
      ),
    [data, sortFacet, sortDirection],
  );
  const tableData = useMemo(() => createTableData(trades, t), [trades, t]);

  const TableComponent = tableRefactor?.tradeHistory ? (
    <TradeHistoryTable
      trades={trades}
      loading={loading}
      error={error ? t('common:somethingWentWrong') : undefined}
      sortColumn={sortFacet}
      sortDirection={sortDirection}
      onSortChanged={handleSortUpdated}
    />
  ) : (
    <Table
      data={tableData}
      loading={loading}
      headers={headers}
      error={error?.message ?? null}
      emptyTableText={t('trade:emptyTrades')}
      EmptyTableAction={
        <Button variant="contained" color="primary" size="large" onClick={() => history.push('/discover')}>
          {t('common:discover')}
        </Button>
      }
    />
  );

  return <div className={classes.container}>{TableComponent}</div>;
}

export default function RecentTradesTableWithContext(): JSX.Element {
  return (
    <SelectedRowsProvider>
      <RecentTradesTable />
    </SelectedRowsProvider>
  );
}
