import { ActiveMarketExtended } from '../../../../../types/ActiveMarketType';
import { BidsAndOffersEnum, ISetFiltersAndSort, SortOverides } from '../types';
import { getAllQueryParams } from '../../../../../hooks/useQueryParameters';
import { SortDirection } from '../../../../../components/Table/types';
import { ServerSideFilterConfigType, ServerSideFiltersType } from '../../../../../components/Filters/serversFilter';

export const SORT_OVERRIDES: SortOverides<ActiveMarketExtended>[] = [{ sortKey: 'WineName', customSortFunction: null }];

// const capitalizeFirstLetter = (str: string, execution?: string[]) => {
//   if (!str) return '';
//   if (execution?.includes(str)) return str;
//   return str
//     .trim()
//     .split(' ')
//     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
//     .join(' ');
// };

const getOverRides = (filter: string, selected: string[]) => {
  const overrides = [
    {
      filter: 'bidsAndOffers',
      selected: [
        {
          value: BidsAndOffersEnum.all_wines_with_a_offer,
          replaceValue: BidsAndOffersEnum.allWinesWithAOffer,
        },
        {
          value: BidsAndOffersEnum.all_wines_with_a_bid,
          replaceValue: BidsAndOffersEnum.allWinesWithABid,
        },
        {
          value: BidsAndOffersEnum.allOfferCapitalized,
          replaceValue: BidsAndOffersEnum.allWinesWithAOffer,
        },
        {
          value: BidsAndOffersEnum.allBidCapitalized,
          replaceValue: BidsAndOffersEnum.allWinesWithABid,
        },
      ],
    },
  ];
  const found = overrides.find((x) => x.filter === filter)?.selected;

  if (!found) return selected;

  const result = selected.map((value) => {
    const replaceSelected = found.find((x) => x.value === value);
    if (replaceSelected?.replaceValue) {
      return replaceSelected?.replaceValue;
    }
    return value;
  });

  return result;
};

export const setFiltersAndSort = ({
  filterViewRef,
  setFilterConfig,
  setSortDirection,
  setSortFacet,
  sortOptions,
}: ISetFiltersAndSort) => {
  const params = getAllQueryParams();
  const paramKeys = Object.keys(params);
  const filtersData = filterViewRef.current?.getFilters() as ServerSideFiltersType;
  const filtersKeys = Object.keys(filtersData ?? {});
  let isFiltered = false;
  const newFilters = { ...filtersData };

  const overrides = [{ param: 'priceRange', key: 'price' }];
  filtersKeys.forEach((filtersKey) => {
    const paramKey = overrides.find((x) => x.key === filtersKey)?.param ?? filtersKey;
    if (paramKeys.includes(paramKey)) {
      isFiltered = true;
      const selected = params[paramKey].split(',').filter((x) => x);

      newFilters[filtersKey] = {
        ...newFilters[filtersKey],
        selected: selected,
        previewSelected: selected,
      };
    }
  });

  if (isFiltered) {
    filterViewRef.current?.setFiltersFunc(newFilters);
  }

  /// for sort section ============

  const hasSort = paramKeys.includes('sortBy');

  if (hasSort && sortOptions) {
    const url = new URL(`${window.location}`);

    const opt = sortOptions.find((x) => x.key === params.sortBy);
    const direction = ['asc', 'desc'].find((x) => x === params.direction) as SortDirection;

    if (opt) {
      if (SORT_OVERRIDES.find((x) => x.sortKey === opt.key)) {
        setFilterConfig((prev) => ({
          ...prev,
          showSortResult: true,
        }));
      } else {
        setFilterConfig((prev) => ({
          ...prev,
          showSortResult: false,
        }));
      }

      setSortFacet(opt.key);

      if (direction) {
        setSortDirection(direction);
        // url.searchParams.delete('direction');
      }

      url.searchParams.delete('sortBy');

      window.history.replaceState({}, '', url);
    }
  }

  setFilterConfig((prev) => ({
    ...prev,
    paramsCheckRan: true,
  }));
};

export const setUrlFilterParams = (filters: ServerSideFiltersType) => {
  const url = new URL(`${window.location}`);
  const overrides = [{ param: 'priceRange', filter: 'price' }];

  const exclusions: string[] = [];
  const paramsArray: string[] = [];

  Object.keys(filters)
    .filter((x) => !exclusions.includes(x))
    .forEach((filterKey) => {
      const paramName = overrides.find((x) => x.filter === filterKey)?.param ?? filterKey;
      const value = filters[filterKey].selected
        .filter((x) => x)
        .filter((x) => x !== `${filters[filterKey].selectAllText}`)
        .map((x) => encodeURIComponent(x))
        .toString();

      if (value) {
        paramsArray.push(`${paramName}=${value}`);
      }
    });

  const newUrl = `${url.origin}${url.pathname}${paramsArray.length ? `?${paramsArray.join('&')}` : ''}`;
  window.history.replaceState(null, '', newUrl);
};

export const getfilters = (filterConfig: ServerSideFilterConfigType | undefined) => {
  const filterObj: Record<string, string> = {};
  const filterRecords = filterConfig?.filters ?? {};

  Object.keys(filterRecords).forEach((filter) => {
    const values = filterRecords?.[filter].selected
      .filter((x) => x)
      .filter((x) => x !== `${filterRecords?.[filter].selectAllText}`);

    if (values.length) {
      filterObj[filter] = getOverRides(filter, values).toString();
    }
  });

  return filterObj;
};
