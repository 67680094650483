/* eslint-disable @typescript-eslint/no-unused-vars */
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useReactiveVar } from '@apollo/client';
import React, { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Divider from '../../components/Divider';
import Typography from '../../components/Typography';
import SearchInput from '../../components/SearchInput';
import { useSearchBar } from '../../hooks/useSearchBar';
import { useQueryParameters } from '../../hooks';
import useMediaQuery from '../../hooks/useMediaQuery';
import ActiveMarkets from './components/ActiveMarkets';
import { isLoggedInVar } from '../../graphql/cache';
import { useAppLayoutContext } from '../../components/AppLayout/Context';
import { ActionEventType, UrlPath } from '../../types/Enums';
import SEO from '../../components/SEO';
import { ReactComponent as CardArrowIcon } from '../../assets/icons/card-arrow-icon.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/card-wallet-icon.svg';
import { ReactComponent as DeliveryIcon } from '../../assets/icons/card-delivery-icon.svg';
import { ActiveMarketSortObject } from './components/ActiveMarkets/types';
import DiscoverCards from './components/DiscoverCards';

const pageTitle = 'Discover Fine Wines for Investment & Trade | CultX';
const pageDescription =
  'Explore fine wines to buy, bid, or offer on CultX. Search and filter regions, vintages and producers on the leading fine wine investment and trading platform.';
const useStyles = makeStyles((theme) => ({
  leftColumn: {
    [theme.breakpoints.up('lg')]: {
      /**
       * Emulate the divider, it's an absolute
       * nightmare trying to get <Divider /> to
       * work with <Grid /> without completely
       * messing up everything we have so far.
       */
      borderWidth: '0 1px 0 0', // right side
      borderColor: theme.palette.dividers.primary,
      borderStyle: 'solid',
    },
  },
  articlesContainer: {
    padding: theme.spacing(7),
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
  },
  suggestedSearches: {
    marginBottom: theme.spacing(4),
  },
  separator: {
    border: 'none',
    margin: 0,
    flexShrink: 0,
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      marginBottom: 10,
      height: 1,
      borderLeft: 0,
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      left: 0,
    },
  },
  search: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(8),
    marginTop: theme.spacing(8),
  },
  searchRoot: {
    width: '75%',
  },
  searchInputRoot: {
    width: '100%',
    borderRadius: '35px',
    background: theme.palette.grey[50],
    padding: `${theme.spacing(0.25)} ${theme.spacing(4)}`,
    height: 'auto',
    fontSize: theme.typography.subtitle1.fontSize,
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}));

export default function Discover(): JSX.Element {
  const {
    state: { searchAndFilters },
    dispatch: appStateDispatcher,
  } = useAppLayoutContext();
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const { searchBreakingChanges, desktopOnly } = useFlags();
  const query = useQueryParameters();
  const searchTermQueryParameter = query.get('term');
  const theme = useTheme();
  const { activeMarket } = useFlags();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [sortFacet, setSortFacet] = React.useState<keyof ActiveMarketSortObject | null>(null);
  const onSearchSubmit = (value: string) => null;

  const {
    setSearchInputValue,
    searchBarActive,
    handleAppellationSuggestionSelected,
    handleProducerSuggestionSelected,
    handleRegionSelected,
    handleSearchTermSubmitted,
    handleSearchValueChanged,
    handleWineVintageSelected,
    searchInputValue,
    handleClickAway,
    handleSearchBarFocus,
    handleClearSearchInput,
  } = useSearchBar({ initialSearchTerm: searchTermQueryParameter ?? undefined, onSubmit: onSearchSubmit });

  useEffect(() => {
    if (searchAndFilters.seachText) {
      setSearchInputValue(searchAndFilters.seachText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    appStateDispatcher({
      type: ActionEventType.SEARCH_AND_FILTERS,
      payload: {
        seachText: searchInputValue,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);

  return (
    <>
      <Grid spacing={4} container>
        <Grid xs={12} spacing={4} item container>
          <DiscoverCards
            cards={[
              {
                isActive: sortFacet === 'BestOffers',
                title: t('common:cards.bestOffers.title'),
                text: t('common:cards.bestOffers.text'),
                ctaText: t('common:cards.bestOffers.cta'),
                onCta: () => {
                  setSortFacet('BestOffers');
                },
                ctaIcon: <CardArrowIcon stroke={sortFacet === 'BestOffers' ? 'white' : '#FB9D0E'} />,
              },
              {
                isActive: sortFacet === 'BestBids',
                title: t('common:cards.bestBids.title'),
                text: t('common:cards.bestBids.text'),
                ctaText: t('common:cards.bestBids.cta'),
                onCta: () => {
                  setSortFacet('BestBids');
                },
                ctaIcon: <CardArrowIcon stroke={sortFacet === 'BestBids' ? 'white' : '#FB9D0E'} />,
              },
              {
                title: t('common:cards.biddingHeadroom.title'),
                text: t('common:cards.biddingHeadroom.text'),
                ctaText: t('common:cards.biddingHeadroom.cta'),
                onCta: () => {},
                link: '/wallet',
                ctaIcon: <WalletIcon />,
              },
              {
                title: t('common:cards.requestDelivery.title'),
                text: t('common:cards.requestDelivery.text'),
                ctaText: t('common:cards.requestDelivery.cta'),
                onCta: () => {},
                link: '/portfolio',
                ctaIcon: <DeliveryIcon />,
              },
            ]}
          />
          {searchBreakingChanges && (
            <>
              {!desktopOnly && (
                <div
                  className={classes.search}
                  style={{
                    marginBottom: lessThanMd ? theme.spacing(-2) : theme.spacing(8),
                  }}
                >
                  <SearchInput
                    isLoggedIn={isLoggedIn}
                    active={searchBarActive}
                    onFocus={handleSearchBarFocus}
                    onClickAway={handleClickAway}
                    inputValue={searchInputValue}
                    onChange={handleSearchValueChanged}
                    onClearInput={handleClearSearchInput}
                    searchTermSubmitted={handleSearchTermSubmitted}
                    placeholder={t('overview:searchPlaceholder')}
                    classes={{ root: classes.searchRoot, searchBarRoot: classes.searchInputRoot }}
                    wineVintageSelected={handleWineVintageSelected}
                    regionSelected={handleRegionSelected}
                    appellationSelected={handleAppellationSuggestionSelected}
                    producerSelected={handleProducerSuggestionSelected}
                  />
                </div>
              )}
              <Grid item xs={12}>
                {/* <SuggestedSearches className={classes.suggestedSearches} /> */}
                <Divider variant="fullWidth" />
              </Grid>
              {activeMarket && (
                <Grid item xs={12}>
                  <ActiveMarkets
                    searchTextIn={searchInputValue}
                    sortFacetIn={sortFacet}
                    setSortFacetIn={setSortFacet}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <SEO title={`${pageTitle}`} description={`${pageDescription}`} path={`${window.location.origin}/discover`} />
    </>
  );
}
