/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import { useSelectedRowsContext } from '../../context/selectedRowsContext';
import { formatter, formatterWholeNumber } from '../../utils/currencyFormatter';
import { deltaPercentage } from '../../utils/deltaPercentage';
import { isNull } from '../../utils/isNull';
import mapRegionName from '../../utils/mapRegionName';
import CalculationToolTip from '../CalculationToolTip';
import { DrinkingWindowAdvice } from '../DrinkingWindowChip';
import GridItem from '../SearchResults/GridItem';
import { getActions } from '../Table/Cells/Actions';
import { Actions } from '../Table/types';
import Typography from '../Typography';
import DrinkingWindow from './DrinkingWindow';
import { useWineCardStyles } from './styles';
import TradingTile from '../TradingTile';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { TradingTileVariant } from '../TradingTile/types';
import PopoverActionButton from '../PopoverActionButton';
import { UrlPath } from '../../types/Enums';
import { allRegionMap } from '../../utils/allRegionMapper';

interface WineCardProps {
  // data1?: ActiveMarketExtended;
  className?: string;
  actions?: Actions;
  /**
   * This is the unique identifier for your record that is represented by the card.
   * It may not always be assetId, which is why the property exists instead
   * of just using the assetId property. It'll be the ID provided in the callbacks
   * for the various actions that you provide for the card (e.g. for orders it's orderId).
   */
  productUrn?: string;
  cardId: string;
  assetId: number;
  imageUrl: string;
  score: number | null;
  vintage: number;
  wineName: string;
  region: string;
  appellation?: string;
  /**
   * TODO: The neccessity for this indicates how much of a technical debt this component is, if it wasn't already
   * obvious from the huge parameters list and amount of logic inside this component.
   * https://dev.azure.com/CultWines/Cult%20Wines%20Platform/_workitems/edit/13977
   */
  performancePercentageDelta?: { value: number | null; display1YRLabel?: boolean } | number | null;
  totalMarketValue?: number;
  totalPurchasePrice?: number;
  totalAvgPurchasePrice?: number;
  marketValue?: number;
  units?: number;
  unitCount?: number;
  unitSize?: string;
  highestBid?: number | null;
  myBid?: number;
  myOffer?: number;
  lowestOffer?: number | null;
  offerCreatedDate?: string;
  bidCreatedDate?: string;
  spread?: number | null;
  activeOffers?: number;
  quantityOwned?: number;
  quantity?: number;
  outstandingQuantity?: number;
  drinkingWindow?: {
    advice: DrinkingWindowAdvice;
    dateFrom: string;
    dateTo: string;
  };
  ExpandedContent?: React.ReactNode;
  marketValueTooltipText?: string;
  /**
   * @deprecated Use tradeTileVariant prop instead.
   */
  displayOrderCreatedDate?: boolean;
  tradeTileVariant?: TradingTileVariant;
  isLogin?: boolean;
  useLink?: boolean;
  handleClickOverRide?: (() => void) | null;
  isClickOveride?: boolean;
}

const More = () => {
  const { t } = useTranslation();
  return (
    <>
      {t('common:showMore')} <ExpandMore />
    </>
  );
};
const Less = () => {
  const { t } = useTranslation();
  return (
    <>
      {t('common:showLess')} <ExpandLess />
    </>
  );
};

/**
 * @abstract
 * A sort of abstract base component, not really intended to be directly used by other views, designed
 * more to be a consistent starting point which specific wine cards (e.g. wine vintage search results)
 * can use to extend and configure, whilst keeping a common look and feel with other wine cards in the app.
 */
export default function WineCard({
  // data1
  actions,
  className = '',
  cardId: id,
  assetId,
  imageUrl,
  vintage,
  appellation,
  performancePercentageDelta,
  region,
  score,
  wineName,
  drinkingWindow,
  highestBid,
  myBid,
  myOffer,
  lowestOffer,
  spread,
  marketValue,
  totalMarketValue,
  totalPurchasePrice,
  totalAvgPurchasePrice,
  units,
  unitCount,
  unitSize,
  activeOffers,
  quantityOwned,
  quantity,
  outstandingQuantity,
  ExpandedContent,
  marketValueTooltipText,
  tradeTileVariant,
  displayOrderCreatedDate,
  bidCreatedDate,
  offerCreatedDate,
  isLogin = true,
  productUrn = '',
  useLink,
  handleClickOverRide = null,
  isClickOveride,
}: WineCardProps): JSX.Element {
  const { state, dispatch } = useSelectedRowsContext();
  const history = useHistory();
  const regionData = allRegionMap.find((x) => x.region.toLowerCase() === region.toLowerCase());
  const classes = useWineCardStyles({ region: mapRegionName(regionData?.allocatedRegion ?? region) });
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const isSelected = state.selectedRows.some((row) => row === id);
  // Close subcontent tab once transfer action has been cancelled
  React.useEffect(() => {
    if (!state.expandedTransferContent) {
      setExpanded(false);
    }
  }, [state.expandedTransferContent]);

  function handleExpandButtonClick(): void {
    setExpanded(!expanded);
  }

  const getSpread = (spreadNumber: number | null | undefined) => {
    if (isNull(spreadNumber) || !spreadNumber) return '-';

    if (spreadNumber > 0) return <span className="tw-text-[#1FE85C]">{`+${spreadNumber}%`}</span>;

    return <span className="tw-text-[#EC2E48]">{`-${spreadNumber}%`}</span>;
  };

  function handleCardSelected(): void {
    if (!isSelected) {
      if (state.limitReached) {
        return;
      }
      dispatch({ type: 'rowChecked', payload: id });
    } else {
      dispatch({ type: 'rowUnchecked', payload: id });
    }
  }

  function handleSignInClick() {
    history.push(`/login`);
  }

  function handleLinkClick() {
    if (state.selectModeActive) {
      return;
    }

    if (handleClickOverRide) {
      handleClickOverRide();
      return;
    }
    history.push(`.${UrlPath.PRODUCT_PAGE}/${productUrn}`);
  }

  const { zellarColour, deltaSymbol } = deltaPercentage(
    typeof performancePercentageDelta === 'number' ? performancePercentageDelta : performancePercentageDelta?.value,
  );

  return (
    <div
      data-testid="card-container"
      className={clsx({
        [classes.container]: true,
        [className]: true,
        [classes.selectable]: state.selectModeActive,
        [classes.selected]: isSelected,
      })}
    >
      <div
        className={classes.mainContent}
        onClick={state.selectModeActive ? handleCardSelected : undefined}
        data-testid="select-asset"
      >
        {/* <div className="tw-flex tw-flex-row tw-justify-between tw-border-b-gray-600">
          <p className="tw-text-red tw-font-bold">Offer created date</p>
          <p className="tw-text-red">
            {data1?.lowestOffer?.createdDate ? new Date(data1?.lowestOffer?.createdDate).toLocaleDateString() : '-'}
          </p>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between tw-border-b-gray-600">
          <p className="tw-text-red tw-font-bold">Best offer</p>
          <p className="tw-text-red">{data1?.bestOffer?.toFixed(4) ?? '-'}</p>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between tw-border-b-gray-600">
          <p className="tw-text-red tw-font-bold">Bid created date</p>
          <p className="tw-text-red ">
            {data1?.highestBid?.createdDate ? new Date(data1?.highestBid?.createdDate).toLocaleDateString() : '-'}
          </p>
        </div>
        <div className="tw-flex tw-flex-row tw-justify-between tw-border-b-gray-600">
          <p className="tw-text-red tw-font-bold">Best bid</p>
          <p className="tw-text-red">{data1?.bestBid?.toFixed(4) ?? '-'}</p>
        </div> */}

        <div className={`${classes.row} ${classes.topRow}`}>
          <div className={classes.flex1}>
            <Typography
              customVariant="caption"
              className="tw-bg-[#B1DED8] tw-h-[30px] tw-w-[40px] tw-flex tw-justify-center tw-items-center tw-rounded tw-text-black tw-text-sm"
            >
              {score === null ? '-' : `${score}`}
            </Typography>
          </div>

          {useLink && !state.selectModeActive ? (
            <Link
              to={`${UrlPath.PRODUCT_PAGE}/${productUrn}`}
              className={`${classes.flex} ${classes.link} tw-no-underline`}
            >
              <div className={classes.imageContainer}>
                <img src={imageUrl} alt="wine" height="152px" />
              </div>
            </Link>
          ) : (
            <button onClick={handleLinkClick} type="button" className={`${classes.flex} ${classes.link}`}>
              <div className={classes.imageContainer}>
                <img src={imageUrl} alt="wine" height="152px" />
              </div>
            </button>
          )}

          <div className={`${classes.actions} ${classes.flex1}`}>
            <div className={classes.actionsInner}>
              {!isClickOveride &&
                actions &&
                Object.keys(actions).length > 0 &&
                getActions(actions, id, t, state.selectModeActive)}
            </div>
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <div className="tw-flex tw-flex-row tw-justify-between tw-gap-5">
            {useLink && !state.selectModeActive ? (
              <Link to={`${UrlPath.PRODUCT_PAGE}/${productUrn}`} className="tw-no-underline">
                <Typography onClick={handleLinkClick} className={`${classes.subtitle1Link} ${classes.link}`}>
                  {wineName} {vintage}
                </Typography>
              </Link>
            ) : (
              <Typography onClick={handleLinkClick} className={`${classes.subtitle1Link} ${classes.link}`}>
                {wineName} {vintage}
              </Typography>
            )}

            {!isUndefined(marketValue) && (
              <div className={classes.oneline}>
                <Typography customVariant="subtitle1" className="tw-text-[#150036]">
                  {marketValue ? formatterWholeNumber.format(marketValue) : '-'}
                </Typography>
                {marketValueTooltipText && (
                  <CalculationToolTip buttonClasses={classes.calculationbuttonTooltip} title={marketValueTooltipText} />
                )}
              </div>
            )}
          </div>

          <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-gap-5">
            <Typography customVariant="subtitle3" zellarColour="textInformation">
              {region} {appellation ? `• ${appellation}` : ''}
              {unitCount && unitSize ? ` • ${unitCount} x ${unitSize}` : ''}
            </Typography>
            {!isNullOrUndefined(performancePercentageDelta) &&
              !isUndefined(marketValue) &&
              performancePercentageDelta !== 0 && (
                <Typography
                  className={classes.marketValuePercentage}
                  customVariant="subtitle3"
                  zellarColour={zellarColour}
                >
                  {deltaSymbol}
                  {performancePercentageDelta}% {t('product:lastTwelveMonths')}
                </Typography>
              )}
          </div>
        </div>
        <div className={classes.row}>
          {!isUndefined(highestBid) && !isUndefined(lowestOffer) && (
            <GridItem
              label={t('product:trading.highestBid')}
              labelVariant="caption"
              value={
                <div className={classes.noWrap}>
                  {(tradeTileVariant === 'date' || displayOrderCreatedDate) && (
                    <TradingTile mode="bid" value={highestBid} createdDate={bidCreatedDate ?? ''} />
                  )}
                  {tradeTileVariant === 'unit-size' && (
                    <TradingTile mode="bid" value={highestBid} unitCount={unitCount ?? 0} unitSize={unitSize ?? ''} />
                  )}
                  {tradeTileVariant === 'basic' &&
                    !isUndefined(actions) &&
                    !isUndefined(actions.trade) &&
                    !isUndefined(actions.trade.onBidClicked) && (
                      <>
                        {!isClickOveride && !isLogin && (
                          <PopoverActionButton
                            onSignInClicked={handleSignInClick}
                            assetId={-1}
                            onProceedClicked={() => actions?.trade?.onBidClicked?.(assetId.toString())}
                          >
                            <TradingTile
                              style={{
                                color: '#150036',
                              }}
                              mode="bid"
                              value={highestBid}
                              onClick={() => {}}
                            />
                          </PopoverActionButton>
                        )}

                        {isClickOveride && !isLogin && (
                          <TradingTile
                            style={{
                              color: '#150036',
                            }}
                            mode="bid"
                            value={highestBid}
                            onClick={() => {
                              handleClickOverRide?.();
                            }}
                          />
                        )}

                        {isLogin && (
                          <TradingTile
                            style={{
                              color: '#150036',
                            }}
                            mode="bid"
                            value={highestBid}
                            onClick={() => actions?.trade?.onBidClicked?.(assetId.toString())}
                          />
                        )}
                      </>
                    )}
                </div>
              }
            />
          )}
          {!isUndefined(highestBid) && isUndefined(lowestOffer) && (
            <GridItem
              label={t('product:trading.highestBid')}
              labelVariant="caption"
              conatinerClassName={classes.spaceBetween}
              value={
                <Typography customVariant="subtitle2" aria-label="highest-bid-no-shading" className="tw-text-[#150036]">
                  {!isNull(highestBid) ? formatterWholeNumber.format(highestBid) : '-'}
                </Typography>
              }
            />
          )}
          {!isUndefined(myBid) && (
            <GridItem
              label={t('trade:myTable.myBid')}
              labelVariant="caption"
              conatinerClassName={classes.spaceBetween}
              value={<Typography customVariant="subtitle2">{formatterWholeNumber.format(myBid ?? 0)}</Typography>}
            />
          )}
          {!isUndefined(lowestOffer) && !isUndefined(highestBid) && (
            <GridItem
              label={t('product:trading.lowestOffer')}
              labelVariant="caption"
              value={
                <div className={classes.noWrap}>
                  {(tradeTileVariant === 'date' || displayOrderCreatedDate) && (
                    <TradingTile mode="offer" value={lowestOffer} createdDate={offerCreatedDate ?? ''} />
                  )}
                  {tradeTileVariant === 'unit-size' && (
                    <TradingTile mode="offer" value={lowestOffer} unitCount={unitCount} unitSize={unitSize} />
                  )}
                  {tradeTileVariant === 'basic' &&
                    !isUndefined(actions) &&
                    !isUndefined(actions.trade) &&
                    !isUndefined(actions.trade.onBuyClicked) && (
                      <>
                        {!isClickOveride && !isLogin && (
                          <PopoverActionButton
                            onSignInClicked={handleSignInClick}
                            assetId={-1}
                            onProceedClicked={() => actions?.trade?.onBidClicked?.(assetId.toString())}
                          >
                            <TradingTile
                              style={{
                                color: '#150036',
                              }}
                              mode="offer"
                              value={lowestOffer}
                              onClick={() => {}}
                            />
                          </PopoverActionButton>
                        )}
                        {isClickOveride && !isLogin && (
                          <TradingTile
                            style={{
                              color: '#150036',
                            }}
                            mode="offer"
                            value={lowestOffer}
                            onClick={() => {
                              handleClickOverRide?.();
                            }}
                          />
                        )}

                        {isLogin && (
                          <TradingTile
                            style={{
                              color: '#150036',
                            }}
                            mode="offer"
                            value={lowestOffer}
                            onClick={() => actions?.trade?.onBuyClicked?.(assetId.toString())}
                          />
                        )}
                      </>
                    )}
                  {!isUndefined(activeOffers) && !isUndefined(quantityOwned) && (
                    <Button className={classes.pill}>
                      {activeOffers}/{quantityOwned} {t('portfolio:activeOffers')}
                    </Button>
                  )}
                </div>
              }
            />
          )}
          {!isUndefined(lowestOffer) && isUndefined(highestBid) && (
            <GridItem
              label={t('product:trading.lowestOffer')}
              labelVariant="caption"
              conatinerClassName={classes.spaceBetween}
              value={
                <Typography customVariant="subtitle2" aria-label="lowest-offer-no-shading">
                  {!isNull(lowestOffer) ? formatterWholeNumber.format(lowestOffer) : '-'}
                </Typography>
              }
            />
          )}
          {!isUndefined(spread) && (
            <GridItem
              label={t('product:trading.spread')}
              labelVariant="caption"
              value={<Typography customVariant="subtitle2">{getSpread(spread)}</Typography>}
            />
          )}

          {!isUndefined(highestBid) && highestBid !== null && highestBid > 0 && (
            <div className={classes.liveBid}>
              <div className="tw-w-[12px] tw-h-[12px] tw-bg-[#FB9D0E] tw-rounded-full" />
              <Typography className="tw-text-xs tw-font-bold">{t('common:liveBid')}</Typography>
            </div>
          )}

          {!isUndefined(myOffer) && (
            <GridItem
              label={t('trade:myTable.myOffer')}
              labelVariant="caption"
              value={<Typography customVariant="subtitle2">{formatterWholeNumber.format(myOffer ?? 0)}</Typography>}
            />
          )}
          {!isUndefined(quantity) && !isUndefined(outstandingQuantity) && (
            <GridItem
              label={t('trade:myTable.outstandingQuantity')}
              labelVariant="caption"
              conatinerClassName={classes.spaceBetween}
              extraElement={<CalculationToolTip title={t('trade:myTable.outstandingQuantityTooltip')} />}
              value={<Typography customVariant="subtitle2">{`${outstandingQuantity}/${quantity}`}</Typography>}
            />
          )}
          {!isUndefined(totalPurchasePrice) && (
            <GridItem
              label={t('portfolio:table.headers.totalPurchasePrice')}
              labelVariant="caption"
              value={formatter.format(totalPurchasePrice)}
            />
          )}
          {!isUndefined(totalAvgPurchasePrice) && (
            <GridItem
              label={t('portfolio:table.headers.totalAvgPurchasePrice')}
              labelVariant="caption"
              value={formatter.format(totalAvgPurchasePrice)}
            />
          )}
          {!isUndefined(totalMarketValue) && !isUndefined(performancePercentageDelta) && (
            <GridItem
              label={t('portfolio:table.headers.totalMarketValuation')}
              labelVariant="caption"
              value={
                <div className={classes.totalMarketValue}>
                  <Typography customVariant="subtitle2">{formatter.format(totalMarketValue)}</Typography>
                  {!isNull(performancePercentageDelta) && (
                    <Typography customVariant="subtitle3" zellarColour={zellarColour}>
                      {deltaSymbol}
                      {typeof performancePercentageDelta === 'number'
                        ? performancePercentageDelta
                        : performancePercentageDelta.value}
                      %{' '}
                      {typeof performancePercentageDelta === 'object' && !performancePercentageDelta.display1YRLabel
                        ? undefined
                        : t('product:lastTwelveMonths')}
                    </Typography>
                  )}
                </div>
              }
            />
          )}
          {!isUndefined(units) && <GridItem label={t('trade:units')} labelVariant="caption" value={`${units}`} />}
          {!isUndefined(drinkingWindow) && (
            <GridItem
              label={t('search:labels.drinkingWindow')}
              labelVariant="caption"
              value={
                <DrinkingWindow
                  className={classes.noWrap}
                  adviceType={drinkingWindow.advice}
                  text={drinkingWindow.dateFrom ? `Start from ${drinkingWindow.dateFrom}` : ''}
                />
              }
            />
          )}
        </div>
      </div>
      {ExpandedContent && (
        <>
          <Collapse in={expanded} mountOnEnter unmountOnExit>
            <div className={`${classes.expandedContent} ${classes.fixedHeightScrollable}`}>{ExpandedContent}</div>
          </Collapse>
          <Button
            color="info"
            className={classes.expandButton}
            onClick={handleExpandButtonClick}
            disabled={state.selectModeActive}
          >
            {expanded ? <Less /> : <More />}
          </Button>
        </>
      )}
    </div>
  );
}
