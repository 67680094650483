import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material';

// import { Card, CardContent, Grid, InputBaseComponentProps } from '@mui/material';
// import TextField from '@mui/material/TextField';
import useMediaQuery from '../../hooks/useMediaQuery';
import DialogTitle from '../Dialog/DialogTitle';
import { useStyles } from './styles';
import Typography from '../Typography';
// import { ReactComponent as MitImage } from '../../assets/images/mit-image.svg';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';

// import NumberFormatCustom from '../CurrencyTextField/NumberFormatCustom';
// import { formatterWholeNumber } from '../../utils/currencyFormatter';

interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export default function MitExplanationDialog({ open, onClose }: ModalProps): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  // const [amount, setAmount] = useState('');

  return (
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown hideBackdrop fullScreen={lessThanMd} maxWidth="sm">
      <div className={classes.modalContainer}>
        <div className={classes.dialogTitle}>
          <DialogTitle id="dialog-title" onClose={onClose}>
            {t('common:benefitsOfMit')}
          </DialogTitle>
        </div>
        <DialogContentBorderTopOnly className={classes.explanationContent} dividers>
          <div className={classes.wrapper}>
            <Typography customVariant="body1">{t('wallet:payByCard.mitExplanationDialog.paragraph1')}</Typography>
            <Typography customVariant="body1">{t('wallet:payByCard.mitExplanationDialog.paragraph2')}</Typography>
            <Typography customVariant="body1">{t('wallet:payByCard.mitExplanationDialog.paragraph3')}</Typography>
          </div>
          {/* <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Card variant="outlined">
              <CardContent className={classes.exampleContent}>
                <Typography variant="h5" className={classes.textCapitalize}>
                  {t('wallet:payByCard.mitExplanationDialog.example.title')}
                </Typography>
                <Typography customVariant="body2">
                  {t('wallet:payByCard.mitExplanationDialog.example.enterAmount')}
                </Typography>
                <TextField
                  id="amount"
                  name="amount"
                  value={amount}
                  placeholder={t('wallet:payByCard.mitExplanationDialog.example.amountPlaceholder')}
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom as unknown as React.ElementType<InputBaseComponentProps>,
                  }}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <Typography customVariant="body2">
                  {t('wallet:payByCard.mitExplanationDialog.example.amountDescription')}
                  <span className={classes.textBold} data-testid="amount-text">
                    {amount
                      ? formatterWholeNumber.format(Number(amount) * 10)
                      : t('wallet:payByCard.mitExplanationDialog.example.amountHere')}
                  </span>
                </Typography>
                <Typography customVariant="body2">
                  {t('wallet:payByCard.mitExplanationDialog.example.footer')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={6} xs={12}>
            <MitImage className={classes.exampleImage} />
          </Grid>
        </Grid> */}
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
}
