/* eslint-disable no-alert */
import React, { useEffect, useMemo } from 'react';
import { GraphQLError } from 'graphql';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import WineCard from '../../../../components/WineCard';
import { Headers, SortDirection } from '../../../../components/Table/types';
import PlaceholderImage from '../../../../assets/images/stub-wine-image.png';
import { SelectedRowsProvider } from '../../../../context/selectedRowsContext';
import TablePagination from '../../../../components/TablePagination';
import ErrorPlaceholder from '../../../../components/ErrorPlaceholder';
import { ViewType } from '../../../../components/Table/Toolbar';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { selectSortOptions, selectTableData, TableData } from './selectors';
import { ActiveMarketsFacets, ActiveMarketSortObject, BidsAndOffersEnum, Modal, PriceRangeEnum } from './types';
import CalculationToolTip from '../../../../components/CalculationToolTip';
import Table from '../../../../components/Table';
import { useActiveMarketAssets } from '../../../../hooks/useActiveMarketAssets';
import getImageUrl from '../../../../utils/getImageUrl';
import WineCardSkeleton from '../../../../components/WineCardSkeleton';
import { uuid } from '../../../../utils/uuid';
import EmptyPlaceholder from '../../../../components/EmptyPlaceholder';
import OrderModal from '../../../../components/OrderModal';
import { Mode } from '../../../../components/OrderModal/types';
import BuyNowModal from '../../../../components/BuyNowModal';
import useCardPaymentCallback from '../../../../hooks/useCardPaymentCallback';
import CardPaymentErrorModal from '../../../../components/CardPaymentCallbackModal/errorContent';
import CardPaymentSuccessModal from '../../../../components/CardPaymentCallbackModal/successContent';
import { useQueryParameters } from '../../../../hooks';
import { selectErrorMessage } from '../../../../graphql/selectors/selectErrorMessage';
import useTableControls from '../../../../hooks/useTableControls';
import { logError } from '../../../../utils/logger';
import { isNullOrUndefined } from '../../../../utils/isNullOrUndefined';
import { TableRefactorFlagSet } from '../../../../types/FeatureFlags';
import ActiveMarketsTable from './Table';
import { isLoggedInVar } from '../../../../graphql/cache';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import Typography from '../../../../components/Typography';
import { useAppLayoutContext } from '../../../../components/AppLayout/Context';
import { ActionEventType } from '../../../../types/Enums';
import DiscoverToolBar from '../DiscoverToolBar';
import { setFiltersAndSort, setUrlFilterParams, SORT_OVERRIDES } from './helper';
import ServersFilter, {
  ServerSideFilterConfigType,
  ServerSideFiltersType,
  ServerSideFilterViewRef,
} from '../../../../components/Filters/serversFilter';
import { ActiveMarketExtended } from '../../../../types/ActiveMarketType';
import { useGetActiveMarketFilters } from '../../../../hooks/useGetActiveMarketFilters';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2.2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },
}));

const ACTIVE_MARKET_PAGE_SIZE = 30;

function ActiveMarkets({
  defaultView,
  searchTextIn = '',
  sortFacetIn,
  setSortFacetIn,
}: {
  defaultView?: ViewType;
  searchTextIn?: string;
  sortFacetIn?: keyof ActiveMarketSortObject | null;
  setSortFacetIn?: React.Dispatch<React.SetStateAction<keyof ActiveMarketSortObject | null>>;
}) {
  const {
    state: { searchAndFilters },
    dispatch: searchAndFilterDispatcher,
  } = useAppLayoutContext();

  const filterViewRef = React.useRef<ServerSideFilterViewRef>(null);
  const { activeMarketWineNameFilter, clickableTradeTiles13816 } = useFlags();
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = useQueryParameters();
  const selectedModal = queryParams.get('modal');
  const selectedAssetId = queryParams.get('assetId');
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [searchtext, setSearchText] = React.useState<string | undefined>(
    activeMarketWineNameFilter ? searchAndFilters.seachText : undefined,
  );
  const [from, setFrom] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [viewType, setViewType] = React.useState<ViewType>(searchAndFilters.viewType || defaultView || 'card');
  const { showCarPaymentErrorModal, showCarPaymentSuccessModal, showTopUpBuySuccessSummaryModal } =
    useCardPaymentCallback();

  const { handleSortUpdated, sortFacet, sortDirection, setSortFacet, setSortDirection } =
    useTableControls<ActiveMarketSortObject>({
      defaultSortDirection: searchAndFilters.sortDirection,
      defaultFacet: searchAndFilters.sortFacet ?? 'LatestOffers',
    });

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const [filterConfig, setFilterConfig] = React.useState<ServerSideFilterConfigType>({
    paramsCheckRan: false,
    isFiltered: searchAndFilters.isFiltered,
    showSortResult: false,
    filters: { ...(searchAndFilters.filters ?? {}) } as ServerSideFiltersType,
  });

  const { results: resultsFilters, loading: loadingFilters } = useGetActiveMarketFilters({
    isLoggedIn,
    filterConfig,
    wineName: searchtext,
  });

  const {
    error,
    loading,
    results: assets,
    total,
  } = useActiveMarketAssets({
    from,
    pageSize: ACTIVE_MARKET_PAGE_SIZE,
    sortBy: sortFacet,
    sortDirection,
    wineName: searchtext,
    isLoggedIn,
    filterConfig,
  });

  const sortOptions = React.useMemo(() => selectSortOptions('asc', sortFacet, t), [sortFacet, t]);
  const currentSelectedSort = React.useMemo(() => {
    return sortOptions.find((x) => x.key.toUpperCase() === String(sortFacet).toUpperCase());
  }, [sortOptions, sortFacet]);

  const [headersConfig, setHeadersConfig] = React.useState<
    Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>
  >({
    wineName: {},
    region: {},
    vintage: {},
    unitSize: {},
    marketValue: {},
    highestBid: {},
    lowestOffer: {},
    spread: {},
  });

  const sortItems = React.useCallback(
    <T,>(direction: SortDirection, prop: keyof T) => {
      if (loading) return;
      const key = prop as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>;
      const opt = sortOptions.find((sO) => sO.key.toUpperCase() === String(key).toUpperCase());

      if (isUndefined(opt)) {
        return;
      }

      Object.keys(headersConfig).forEach((x) => {
        if (x !== key)
          setHeadersConfig((prev) => ({
            ...prev,
            [x]: { ...prev[x as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>], active: false },
          }));
      });

      setHeadersConfig((prev) => ({
        ...prev,
        [prop]: { ...prev[key], direction, active: true },
      }));

      if (SORT_OVERRIDES.find((x) => x.sortKey === opt.key)) {
        setFilterConfig((prev) => ({
          ...prev,
          showSortResult: true,
        }));
      } else {
        setFilterConfig((prev) => ({
          ...prev,
          showSortResult: false,
        }));
      }

      handleSortUpdated(opt.key, direction);
    },
    [sortOptions, handleSortUpdated, headersConfig, loading, setFilterConfig],
  );

  const headers = useMemo((): Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets> => {
    const wineClassName = 'tw-bg-white  tw-min-w-[500px]';
    const defaultClassName = 'tw-bg-white tw-w-[110px] tw-min-w-[110px] tw-max-[110px] tw-whitespace-nowrap';
    const sxStyle = { border: '1px solid #00000033', color: '#8A809A' };
    return {
      region: {
        ...headersConfig?.region,
        className: defaultClassName,
        sx: sxStyle,
        title: t('common:region').toUpperCase(),
        id: 'region',
        onClick: (id, direction) => {
          sortItems(direction, 'region');
        },
      },
      wineName: {
        ...headersConfig?.wineName,
        sx: sxStyle,
        className: wineClassName,
        title: t('market:table.wineName').toUpperCase(),
        id: 'name',
        onClick: (id, direction) => {
          sortItems(direction, 'wineName');
        },
      },

      vintage: {
        ...headersConfig?.vintage,
        className: defaultClassName,
        sx: sxStyle,
        title: t('common:vintage').toUpperCase(),
        id: 'vintage',
        onClick: (id, direction) => {
          sortItems(direction, 'vintage');
        },
      },
      unitSize: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('common:unitSize').toUpperCase(),
        id: 'unitSize',
        ...headersConfig?.unitSize,
        onClick: (id, direction) => {
          sortItems(direction, 'unitSize');
        },
      },
      marketValue: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:marketValue').toUpperCase(),
        id: 'marketValue',
        extraElement: <CalculationToolTip title={t('market:marketValueCalculation')} />,
        ...headersConfig?.marketValue,
        onClick: (id, direction) => {
          sortItems(direction, 'marketValue');
        },
      },
      highestBid: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:trading.highestBid').toUpperCase(),
        id: 'highestBid',
        ...headersConfig?.highestBid,
        onClick: (id, direction) => {
          sortItems(direction, 'highestBid');
        },
      },
      lowestOffer: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:trading.lowestOffer').toUpperCase(),
        id: 'lowestOffer',
        ...headersConfig?.lowestOffer,
        onClick: (id, direction) => {
          sortItems(direction, 'lowestOffer');
        },
      },
      spread: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:trading.spread').toUpperCase(),
        id: 'spread',
        ...headersConfig?.spread,
        onClick: (id, direction) => {
          sortItems(direction, 'spread');
        },
      },
      actions: {
        sx: sxStyle,
        title: t('common:action').toUpperCase(),
        className: defaultClassName,
      },
    };
  }, [headersConfig, t, sortItems]);

  React.useEffect(() => {
    if (error) {
      logError({
        error: new Error('Failed to load active market assets'),
        originalError: error,
        filename: 'src/views/Discover/components/ActiveMarkets',
        additionalInfo: {
          searchtext: searchtext ?? '',
          sortDirection,
          sortFacet,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (filterConfig.paramsCheckRan || !sortOptions) return;
    setFiltersAndSort({ filterViewRef, setFilterConfig, setSortDirection, setSortFacet, sortOptions });
  }, [filterViewRef, sortOptions, setSortFacet, setSortDirection, setFilterConfig, filterConfig]);

  useEffect(() => {
    setSearchText(searchTextIn);
    if (searchTextIn) {
      filterViewRef.current?.resetFilters?.();
    }
  }, [searchTextIn]);

  useEffect(() => {
    if (!sortFacetIn) return;
    setSortFacet(sortFacetIn);
    setFilterConfig((prev) => ({
      ...prev,
      showSortResult: true,
    }));
  }, [sortFacetIn, setSortFacet, setFilterConfig]);

  useEffect(() => {
    if (sortFacet !== 'BestBids' && sortFacet !== 'BestOffers') setSortFacetIn?.(null);
  }, [setSortFacetIn, sortFacet]);

  useEffect(() => {
    searchAndFilterDispatcher({
      type: ActionEventType.SEARCH_AND_FILTERS,
      payload: {
        filters: filterConfig.filters,
        isFiltered: filterConfig.isFiltered,
        sortDirection: sortDirection,
        sortFacet: sortFacet,
        viewType: viewType,
      },
    });
  }, [filterConfig.isFiltered, filterConfig.filters, sortFacet, sortDirection, searchAndFilterDispatcher, viewType]);

  function handleToolbarSortChanged(id: string): void {
    const opt = sortOptions.find((sO) => sO.id === id);

    if (isUndefined(opt)) {
      return;
    }

    const key = opt.filterKey as keyof ActiveMarketExtended;

    Object.keys(headersConfig).forEach((x) => {
      if (x !== key)
        setHeadersConfig((prev) => ({
          ...prev,
          [x]: { ...prev[x as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>], active: false },
        }));
    });

    setHeadersConfig((prev) => ({
      ...prev,
      [key]: {
        ...prev[key as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>],
        direction: opt.direction,
        active: true,
      },
    }));

    if (SORT_OVERRIDES.find((x) => x.sortKey === opt.key)) {
      setFilterConfig((prev) => ({
        ...prev,
        showSortResult: true,
      }));
    } else {
      setFilterConfig((prev) => ({
        ...prev,
        showSortResult: false,
      }));
    }

    handleSortUpdated(opt.key, opt.direction);
  }

  const handleChangePage = async (_: React.MouseEvent<HTMLButtonElement> | null, _pageNumber: number) => {
    setPageNumber(_pageNumber);
    setFrom(_pageNumber * ACTIVE_MARKET_PAGE_SIZE);
    searchAndFilterDispatcher({ type: ActionEventType.SCROLL_TO_EVENT, payload: { x: 0, y: 0 } });
  };

  function handleAddToWatchlist() {
    enqueueSnackbar(t('product:watchList.addedMessage'), {
      variant: 'info',
    });
  }

  function handleRemoveFromWatchlist() {
    enqueueSnackbar(t('product:watchList.deletedMessage'), {
      variant: 'info',
    });
  }

  function handleWatchlistError(errors: readonly GraphQLError[]) {
    enqueueSnackbar(selectErrorMessage(errors), {
      variant: 'error',
    });
  }

  function handleOpenBuyNowModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.BuyNow}&assetId=${rowId}`);
  }

  function handleOpenBidModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.Bid}&assetId=${rowId}`);
  }

  function handleOpenOfferModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.Offer}&assetId=${rowId}`);
  }

  function handleCloseModal() {
    queryParams.delete('modal');
    queryParams.delete('assetId');
    history.push({ pathname: history.location.pathname, search: queryParams.toString(), hash: history.location.hash });
  }

  function closeCardPaymentErrorModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  function closeCardPaymentSuccessModal() {
    history.push({ pathname: history.location.pathname, search: history.location.search, hash: '' });
  }

  function handleSignInClick(): void {
    history.push(`/login`);
  }

  const tableData = selectTableData({
    rawData: assets,
    handleAddToWatchlist,
    handleOpenBidModalClicked,
    handleOpenBuyNowModalClicked,
    handleOpenOfferModalClicked,
    handleRemoveFromWatchlist,
    handleWatchlistError,
    clickableTradeTiles13816,
    isLoggedIn,
    handleSignInClick,
  });

  if (!loading && assets.length === 0 && !activeMarketWineNameFilter && !searchtext?.length) {
    return null;
  }

  const TableComponent = tableRefactor?.activeMarkets ? (
    <ActiveMarketsTable
      assets={assets}
      loading={loading}
      error={error ? t('common:somethingWentWrong') : undefined}
      onAddToWatchlist={handleAddToWatchlist}
      onRemoveFromWatchlist={handleRemoveFromWatchlist}
      onWatchlistError={handleWatchlistError}
      onBuyNowClicked={handleOpenBuyNowModalClicked}
      onBidClicked={handleOpenBidModalClicked}
      onOfferClicked={handleOpenOfferModalClicked}
    />
  ) : (
    <Table
      data={tableData}
      headers={headers}
      error={error ? t('common:somethingWentWrong') : null}
      loading={loading}
      emptyTableText={t('discover:activeMarkets.noResultsNoFilter')}
      actionCellSx={{ border: '1px solid #00000033' }}
    />
  );

  return (
    <div>
      <DiscoverToolBar
        viewType={viewType}
        onChangeViewType={setViewType}
        hideChangeViewType={lessThanMd}
        onSortChanged={handleToolbarSortChanged}
        sortOptions={sortOptions}
        preferSortMenuButton
        currentSelectedSort={currentSelectedSort}
        customFillterTool={() => {
          return (
            <ServersFilter
              ref={filterViewRef}
              isLoading={loadingFilters}
              customFilters={{
                region: {
                  label: t('common:filters.dropDownFilters.title.region'),
                  selectAllText: t('common:filters.dropDownFilters.value.region'),
                  selected: [],
                  previewSelected: [],
                  isSearchable: true,
                },

                producer: {
                  label: t('common:filters.dropDownFilters.title.producer'),
                  selectAllText: t('common:filters.dropDownFilters.value.producer'),
                  selected: [],
                  previewSelected: [],
                  isSearchable: true,
                },

                vintage: {
                  label: t('common:filters.dropDownFilters.title.vintage'),
                  selectAllText: t('common:filters.dropDownFilters.value.vintage'),
                  selected: [],
                  previewSelected: [],
                  isNumerical: true,
                  isSearchable: true,
                },

                unitSize: {
                  label: t('common:filters.dropDownFilters.title.size'),
                  selectAllText: t('common:filters.dropDownFilters.value.size'),
                  selected: [],
                  previewSelected: [],
                  isNumerical: true,
                  isAsc: true,
                },

                score: {
                  label: t('common:filters.dropDownFilters.title.score'),
                  selectAllText: t('common:filters.dropDownFilters.value.score'),
                  selected: [],
                  previewSelected: [],
                  isNumerical: true,
                },

                price: {
                  label: t('common:filters.dropDownFilters.title.price'),
                  selectAllText: t('common:filters.dropDownFilters.value.price'),
                  selected: [],
                  previewSelected: [],
                  isAsc: true,
                  customSortOrder: [
                    PriceRangeEnum.Under500,
                    PriceRangeEnum.Under1000,
                    PriceRangeEnum.Range_1k_2k,
                    PriceRangeEnum.Range_2k_5k,
                    PriceRangeEnum.Range_5k_10k,
                    PriceRangeEnum.Range_10k_Plus,
                  ],
                  customItems: [
                    PriceRangeEnum.Under500,
                    PriceRangeEnum.Under1000,
                    PriceRangeEnum.Range_1k_2k,
                    PriceRangeEnum.Range_2k_5k,
                    PriceRangeEnum.Range_5k_10k,
                    PriceRangeEnum.Range_10k_Plus,
                  ],
                },
                bidsAndOffers: {
                  label: t('common:filters.dropDownFilters.title.bidsAndOffers'),
                  selectAllText: t('common:filters.dropDownFilters.value.bidsAndOffers'),
                  selected: [],
                  previewSelected: [],
                  isAsc: true,
                  customItems: [BidsAndOffersEnum.all_wines_with_a_offer, BidsAndOffersEnum.all_wines_with_a_bid],
                  onShowResults: (filters) => {
                    const selected = filters?.bidsAndOffers?.selected;
                    if (selected?.includes(BidsAndOffersEnum.all_wines_with_a_offer)) {
                      setSortFacet('BestOffers');
                    } else {
                      setSortFacet('BestBids');
                    }
                  },
                },
                ...filterConfig.filters,
              }}
              sourceData={resultsFilters}
              setFilteredSource={(isFiltered, filters) => {
                setUrlFilterParams(filters);
                setFilterConfig((prev) => ({
                  ...prev,
                  isFiltered: isFiltered,
                  filters: filters as unknown as ServerSideFiltersType,
                }));

                Object.keys(filters).forEach((key) => {
                  if (filters[key].selected.length > 0) {
                    filters?.[key]?.onShowResults?.(filters);
                  }
                });
              }}
            />
          );
        }}
        customResultCount={() => {
          return (
            <div className="tw-flex tw-flex-row tw-items-center">
              <div className="tw-bg-[#150036] tw-text-[12px] tw-text-white tw-rounded-full tw-mr-3 tw-whitespace-nowrap">
                <Typography customVariant="subtitle1Bold" className="tw-px-3 tw-py-2 tw-text-md">
                  {t('discover:activeMarkets.title')}
                </Typography>
              </div>

              <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-whitespace-nowrap">
                <Typography customVariant="subtitle3" zellarColour="textLabel">
                  {t('common:live')}
                </Typography>

                <div className="tw-w-[12px] tw-h-[12px] tw-bg-[#FB9D0E] tw-rounded-full tw-flex tw-justify-center tw-items-center">
                  <div className="tw-w-[10px] tw-h-[10px] tw-bg-white  tw-rounded-full tw-flex tw-justify-center tw-items-center">
                    <div className="tw-w-[6px] tw-h-[6px] tw-bg-[#FB9D0E] tw-rounded-full" />
                  </div>
                </div>
                <Typography customVariant="subtitle3" zellarColour="textLabel" className="tw-whitespace-nowrap">
                  {total} {total === 1 ? t('common:result') : t('common:results')}
                </Typography>
              </div>
            </div>
          );
        }}
      />

      {viewType === 'table' && !lessThanMd ? (
        TableComponent
      ) : (
        <div className={classes.cards}>
          {loading &&
            !assets.length &&
            !error &&
            new Array(ACTIVE_MARKET_PAGE_SIZE)
              .fill(0)
              .map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
          {error && <ErrorPlaceholder error={t('common:somethingWentWrong')} />}
          {!assets.length && activeMarketWineNameFilter && !searchtext?.length && !error && !loading ? (
            <EmptyPlaceholder label={t('discover:activeMarkets.noResultsNoFilter')} />
          ) : (
            <>
              {assets.map((a) => {
                const tradeTileVariant = clickableTradeTiles13816 ? 'basic' : 'date';

                return (
                  <WineCard
                    useLink
                    productUrn={a?.productUrn ?? ''}
                    key={a.assetId}
                    className={classes.card}
                    assetId={a.assetId}
                    cardId={a.assetId.toString()}
                    imageUrl={a.imageFileName ? getImageUrl(a.imageFileName, { height: 400 }) : PlaceholderImage}
                    region={a.region}
                    score={a.score ?? null}
                    vintage={a.vintage}
                    wineName={a.wineName}
                    spread={a.spread}
                    lowestOffer={a.lowestOffer.price}
                    highestBid={a.highestBid.price}
                    marketValue={a.marketValue}
                    tradeTileVariant={tradeTileVariant}
                    offerCreatedDate={
                      clickableTradeTiles13816 || isNullOrUndefined(a.lowestOffer.createdDate)
                        ? undefined
                        : new Date(a.lowestOffer.createdDate).toLocaleDateString()
                    }
                    bidCreatedDate={
                      clickableTradeTiles13816 || isNullOrUndefined(a.highestBid.createdDate)
                        ? undefined
                        : new Date(a.highestBid.createdDate).toLocaleDateString()
                    }
                    actions={{
                      watchlist: {
                        onAdd: handleAddToWatchlist,
                        onRemove: handleRemoveFromWatchlist,
                        onError: handleWatchlistError,
                      },
                      trade: {
                        assetId: a.assetId,
                        condensed: true,
                        onBidClicked: handleOpenBidModalClicked,
                        onBuyClicked: handleOpenBuyNowModalClicked,
                        onOfferClicked: handleOpenOfferModalClicked,
                      },
                    }}
                    unitCount={a.unitCount}
                    unitSize={a.unitSize}
                    isLogin={isLoggedIn}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
      <TablePagination
        component="div"
        count={total}
        rowsPerPage={ACTIVE_MARKET_PAGE_SIZE}
        page={pageNumber}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[ACTIVE_MARKET_PAGE_SIZE]}
      />
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.Bid && (
        <OrderModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.Bid}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="bid"
        />
      )}
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.Offer && (
        <OrderModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.Offer}
          onClose={handleCloseModal}
          mode={Mode.Create}
          tradeType="offer"
        />
      )}
      {selectedAssetId && selectedModal?.toLowerCase() === Modal.BuyNow && (
        <BuyNowModal
          assetId={Number(selectedAssetId)}
          open={selectedModal === Modal.BuyNow}
          onClose={handleCloseModal}
        />
      )}

      {/* after a success topup on buy modal, url string should include #buy_topup hash and assetId query param thus 
      dynamically render BuyNowModal component so user can continue the buy now flow */}
      {selectedAssetId && showTopUpBuySuccessSummaryModal && (
        <BuyNowModal assetId={Number(selectedAssetId)} open onClose={handleCloseModal} />
      )}

      {showCarPaymentErrorModal && (
        <CardPaymentErrorModal open={showCarPaymentErrorModal} onClose={closeCardPaymentErrorModal} />
      )}
      {showCarPaymentSuccessModal && (
        <CardPaymentSuccessModal open={showCarPaymentSuccessModal} onClose={closeCardPaymentSuccessModal} />
      )}
    </div>
  );
}
export default function ActiveMarketsWithContext({
  defaultView,
  searchTextIn = '',
  sortFacetIn,
  setSortFacetIn,
}: {
  defaultView?: ViewType;
  searchTextIn?: string;
  sortFacetIn?: keyof ActiveMarketSortObject | null;
  setSortFacetIn?: React.Dispatch<React.SetStateAction<keyof ActiveMarketSortObject | null>>;
}): JSX.Element {
  return (
    // TODO: have the market comparison page expose this number so we can never get out of sync.
    // maxRows = 8 because that is the total columns on the market comparison page is 8
    <SelectedRowsProvider defaultState={{ maxRows: 8 }}>
      <ActiveMarkets
        defaultView={defaultView}
        searchTextIn={searchTextIn}
        sortFacetIn={sortFacetIn}
        setSortFacetIn={setSortFacetIn}
      />
    </SelectedRowsProvider>
  );
}
