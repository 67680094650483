import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ServerSideFilterConfigType } from '../components/Filters/serversFilter';
import { GET_ACTIVE_MARKETS_FILTERS } from '../graphql/queries/getActiveMarketsFilters';
import { getfilters } from '../views/Discover/components/ActiveMarkets/helper';

interface Filters {
  producer: string[];
  region: string[];
  score: string[];
  unitCount: string[];
  unitSize: string[];
  vintage: string[];
}

interface UseGetActiveMarketFiltersParams {
  isLoggedIn: boolean;
  filterConfig?: ServerSideFilterConfigType;
  wineName?: string;
}

export function useGetActiveMarketFilters({ isLoggedIn, filterConfig, wineName }: UseGetActiveMarketFiltersParams) {
  const [isInit, setIsInit] = React.useState(true);
  const filters = getfilters(filterConfig);

  const { data, loading, error } = useQuery(GET_ACTIVE_MARKETS_FILTERS, {
    ...(isLoggedIn
      ? {
          variables: {
            filters: filters,
            wineName: wineName,
          },
          fetchPolicy: 'network-only',
        }
      : {
          context: { serviceName: 'insecure' },
          variables: {
            filters: filters,
            wineName: wineName,
          },
          fetchPolicy: 'network-only',
        }),
  });

  const results = useMemo((): Record<string, string[]> => {
    if (!loading) {
      setIsInit(false);
    }

    const dataSet = (
      (!loading && data
        ? data
        : {
            getActiveMarketsFilters: {
              producer: [],
              region: [],
              score: [],
              unitCount: [],
              unitSize: [],
              vintage: [],
            },
          }) as { getActiveMarketsFilters: Filters }
    ).getActiveMarketsFilters;

    return {
      producer: dataSet.producer,
      region: dataSet.region,
      score: dataSet.score,
      unitCount: dataSet.unitCount,
      unitSize: dataSet.unitSize,
      vintage: dataSet.vintage,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return {
    results,
    loading: isInit && loading,
    error: error,
  };
}
