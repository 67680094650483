import { gql } from '@apollo/client';

export const GET_ACTIVE_MARKETS_FILTERS = gql`
  query GetActiveMarketsFilters($filters: ActiveMarketFilters, $from: Int, $pageSize: Int, $wineName: String) {
    getActiveMarketsFilters(filters: $filters, from: $from, pageSize: $pageSize, wineName: $wineName) {
      producer
      region
      unitSize
      vintage
      score
    }
  }
`;
