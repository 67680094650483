import { Namespace, Resources, TFunction } from 'react-i18next';
import WineStubImage from '../../../assets/images/stub-wine-image.png';
import selectDrinkingWindowAdvice from '../../../graphql/selectors/selectDrinkingWindowAdvice';
import getImageUrl from '../../../utils/getImageUrl';
import { humanReadableDate } from '../../../utils/humanReadableDate';
import { isNullOrUndefined } from '../../../utils/isNullOrUndefined';
import { massageToNull } from '../../../utils/massageToNull';
import { selectLiquidityScore, selectSpread, selectWineType } from '../../../views/Search/selectors';
import { DrinkingWindowAdvice } from '../../DrinkingWindowChip';
import { LiquidityScoreRating, WineType } from '../types';
import { ExpandedSearchResultDataQuery } from '../../../__generated__/graphql';

interface WineVintageData {
  imageFileName: string;
  region: string;
  liquidityScore: LiquidityScoreRating | null;
  lastTradeDate: string | null;
  lastTradeValue: number | null | undefined;
  spread: number | null;
  drinkingWindow: {
    advice: DrinkingWindowAdvice;
    text: string;
  };
  wineType: WineType | null;
  classification: string | null | undefined;
  producer: string | null;
}

export function selectExpandedSearchResultData(
  data: ExpandedSearchResultDataQuery | undefined,
  t: TFunction<Namespace<keyof Resources>>,
): WineVintageData | null {
  if (isNullOrUndefined(data)) {
    return null;
  }

  if (isNullOrUndefined(data.productAsset)) {
    return null;
  }

  const { imageFileName } = data.productAsset.vintage;

  return {
    imageFileName: imageFileName ? getImageUrl(imageFileName, { height: 200 }) : WineStubImage,
    drinkingWindow: {
      advice: selectDrinkingWindowAdvice(data.productAsset.vintage.drinkingWindow.advice),
      text:
        data.productAsset.vintage.drinkingWindow.dateFrom.length > 0
          ? `${t('common:drinkingWindow.date')} ${data.productAsset.vintage.drinkingWindow.dateFrom}`
          : '',
    },
    lastTradeDate: data.productAsset.tradingInfo.lastTraded
      ? humanReadableDate(data.productAsset.tradingInfo.lastTraded)
      : null,
    lastTradeValue: data.productAsset.tradingInfo.lastTradedValue,
    liquidityScore: selectLiquidityScore(massageToNull(data.productAsset.calculatedMarketData?.liquidityScore)),
    // Region can sometimes have trailing white space for some reason!?
    region: data?.productAsset?.vintage?.wine?.region?.name?.trim() ?? '',
    spread: selectSpread(data.productAsset.spread?.percentageDifference),
    wineType: selectWineType(data.productAsset.vintage.wine.sparkling),
    classification: data.productAsset.vintage.wine.classification,
    producer: massageToNull(data.productAsset.vintage.wine.producer?.name),
  };
}
