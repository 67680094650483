import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Skeleton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import Typography from '../Typography';
import { colours } from '../../theme/light/palette';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import PaymentCardDetails from '../PaymentCardModal/details';
import PaymentCardForm from '../PaymentCardModal/form';
import MitLearnMore from '../PaymentCardModal/MitLearnMore';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    '@media (min-width: 768px)': {
      width: '525px',
    },
  },
  cardButton: {
    width: '100%',
    background: 'white',
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    padding: theme.spacing(3),
  },
  pill: {
    width: 'fit-content',
    textTransform: 'uppercase',
    padding: '3px 6px',
    borderRadius: '4px',
    backgroundColor: colours.mutedGreen,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  cardIcon: {
    stroke: theme.palette.textPrimary,
  },
  expandIcon: {
    color: theme.palette.textInformation,
    marginLeft: 'auto',
  },
  descriptionText: {
    color: theme.palette.textPrimary,
  },
  learnMore: {
    color: 'black',
    background: 'inherit',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '12px',
    fontFamily: 'Lato',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  checkMark: {
    marginLeft: -4,
  },
}));

interface PayByCardProps {
  amount: number;
  isSubmitting: boolean;
  setIsFormValid: (isValid: boolean) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  hideUseDifferentCard?: boolean;
  customSuccessUri?: string;
}

export default function PayByCard({
  amount,
  isSubmitting,
  setIsFormValid,
  setIsSubmitting,
  hideUseDifferentCard,
  customSuccessUri,
}: PayByCardProps): JSX.Element {
  const classes = useStyles();
  const { storeAndReuseCardDetailsDuringTopup } = useFlags();
  const {
    error,
    data: storedCardDetails,
    loading,
  } = useQuery(GET_STORED_CARD_DETAILS, {
    skip: !storeAndReuseCardDetailsDuringTopup,
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  const DetailSkeleton = () => (
    <div aria-label="display-name-skeleton">
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
      <Typography variant="h2">
        <Skeleton />
      </Typography>
    </div>
  );

  return (
    <div className={classes.modalContainer}>
      {loading ? (
        <DetailSkeleton />
      ) : (
        <>
          {(!storeAndReuseCardDetailsDuringTopup || error || !storedCardDetails?.paymentGetCardDetails?.length) && (
            <PaymentCardForm
              amount={amount}
              showMakeDefault={false}
              isSubmitting={isSubmitting}
              setIsFormValid={setIsFormValid}
              setIsSubmitting={setIsSubmitting}
              ExpandedContent={<MitLearnMore />}
            />
          )}

          {storeAndReuseCardDetailsDuringTopup && !!storedCardDetails?.paymentGetCardDetails?.length && (
            <PaymentCardDetails
              customSuccessUri={customSuccessUri}
              hideUseDifferentCard={hideUseDifferentCard}
              amount={amount}
              isSubmitting={isSubmitting}
              setIsFormValid={setIsFormValid}
              setIsSubmitting={setIsSubmitting}
            />
          )}
        </>
      )}
    </div>
  );
}
