import React from 'react';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import DialogTitle from '../Dialog/DialogTitle';
import Typography from '../Typography';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    '@media (min-width: 768px)': {
      width: '420px',
    },
  },
  dialogTitle: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  explanationContent: {
    padding: 15,
    gap: theme.spacing(6),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

interface ModalProps {
  onClose: () => void;
  open: boolean;
  fullScreen: boolean;
}

export default function MitExplanation({ onClose, open, fullScreen }: ModalProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      fullScreen={fullScreen}
      disableEscapeKeyDown
      hideBackdrop
      maxWidth="sm"
    >
      <div className={classes.modalContainer}>
        <div className={classes.dialogTitle}>
          <DialogTitle id="dialog-title" onClose={onClose}>
            {t('wallet:payByCard.mitExplanationDialog.title')}
          </DialogTitle>
        </div>
        <DialogContentBorderTopOnly className={classes.explanationContent} dividers>
          <div className={classes.wrapper}>
            <Typography customVariant="body1">{t('wallet:mitExplanationModal.paragraph1')}</Typography>
            <Typography customVariant="body1">{t('wallet:mitExplanationModal.paragraph2')}</Typography>
            <Typography customVariant="body1">{t('wallet:mitExplanationModal.paragraph3')}</Typography>
          </div>
        </DialogContentBorderTopOnly>
      </div>
    </Dialog>
  );
}
