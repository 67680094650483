import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from '@mui/lab';
import { DialogActions } from '@mui/material';
import PaymentCardForm from './form';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContent } from '../Dialog/DialogContent';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg';
import { useStyles } from './styles';
import MitLearnMore from './MitLearnMore';

interface ModalProps {
  amount: number;
  open: boolean;
  onClose: () => void;
}

export default function AddPaymentCardModal({ open, amount, onClose }: ModalProps): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  return (
    <Dialog onClose={onClose} open={open} disableEscapeKeyDown maxWidth="md">
      <div className={classes.modalContainer}>
        <div className={classes.dialogTitle}>
          <DialogTitle id="dialog-title" onClose={onClose}>
            <div className={classes.iconTitle}>
              <CreditCardIcon className={classes.cardIcon} />
              {t('wallet:payByCard.addCardModal.title')}
            </div>
          </DialogTitle>
        </div>
        <DialogContent className={classes.modalContent}>
          <PaymentCardForm
            amount={amount}
            showMakeDefault={false}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            setIsFormValid={setIsFormValid}
            ExpandedContent={<MitLearnMore />}
          />
        </DialogContent>
        <DialogActions className={classes.buttonContainer}>
          <LoadingButton
            className={classes.payNowButton}
            color="primary"
            variant="contained"
            size="large"
            loading={isSubmitting}
            onClick={() => setIsSubmitting(true)}
            disabled={isSubmitting || !isFormValid}
          >
            {t('common:confirm')}
          </LoadingButton>
        </DialogActions>
      </div>
    </Dialog>
  );
}
