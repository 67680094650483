import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { DialogActions, Stack, useTheme } from '@mui/material';
import clsx from 'clsx';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isNull } from '@cultwines/zellar-client-sdk/utils/isNull';
import { LoadingButton } from '@mui/lab';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit-card.svg';
import Typography from '../Typography';
import PaymentCardForm from '../PaymentCardModal/form';
import DialogTitle from '../Dialog/DialogTitle';
import { DialogContentBorderTopOnly } from '../Dialog/DialogContent';
import useUserDetails from '../../hooks/useUserDetails';
import { AccountType } from '../../types/AccountType';
import { ReactComponent as CheckMark } from '../../assets/icons/checkmark-yellow.svg';
import { GET_STORED_CARD_DETAILS } from '../../graphql/queries/storedCardDetails';
import BillingAddress from './BillingAddress';
import { useAddress } from '../../hooks/useAddress';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    '@media (min-width: 768px)': {
      width: '525px',
    },
  },
  dialogTitle: {
    paddingLeft: 15,
    paddingRight: 15,
    textTransform: 'capitalize',
  },
  modalContent: {
    padding: 8,
    paddingTop: 30,
    paddingBottom: 0,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  payNowButton: {
    marginTop: '12px',
  },
  cardButton: {
    width: '100%',
    background: 'white',
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    padding: theme.spacing(3),
  },
  cardIcon: {
    stroke: theme.palette.textPrimary,
  },
  descriptionText: {
    color: theme.palette.textPrimary,
  },
  learnMore: {
    color: 'black',
    background: 'inherit',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '12px',
    fontFamily: 'Lato',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  checkMark: {
    marginLeft: -4,
  },
  buttonContainer: {
    paddingTop: 0,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    background: theme.palette.grey[50],
  },
}));

interface PayByCardProps {
  onClose: () => void;
  onMITExplanation: () => void;
  openAddressModal: () => void;
  customSuccessUri?: string;
}

export default function AddPaymentCardModal({
  onClose,
  onMITExplanation,
  openAddressModal,
  customSuccessUri,
}: PayByCardProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { whaleAccount13107, billingAddressTopUpModal13811 } = useFlags();
  const { userDetails } = useUserDetails();
  const { address, loading: loadingAddress, error: addressError } = useAddress({ addressType: 'Billing' });
  const { data: paymentCards } = useQuery(GET_STORED_CARD_DETAILS);
  const isWhaleAccount = userDetails?.accountType === AccountType.Whale;
  const haveNoMITCard = paymentCards?.paymentGetCardDetails?.every((x) => x.mitConsentedFlag === false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  return (
    <div className={classes.modalContainer}>
      <div className={classes.dialogTitle}>
        <DialogTitle id="dialog-title" onClose={onClose}>
          {t('wallet:addPaymentCardModal.title')}
        </DialogTitle>
      </div>
      <DialogContentBorderTopOnly className={classes.modalContent} dividers>
        <div className={classes.cardButton}>
          <CreditCardIcon className={classes.cardIcon} />
          <Typography variant="subtitle1">{t('wallet:payByCard.title')}</Typography>
        </div>

        <Stack bgcolor={theme.palette.grey[50]} mx={1}>
          {billingAddressTopUpModal13811 && <BillingAddress address={address} openAddressModal={openAddressModal} />}
          <PaymentCardForm
            customSuccessUri={customSuccessUri}
            amount={0}
            showStoreCard={false}
            storeCardFlag
            mitConsentedFlag={whaleAccount13107 && isWhaleAccount}
            defaultMitFlag={whaleAccount13107 && isWhaleAccount && haveNoMITCard}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            setIsFormValid={setIsFormValid}
            ExpandedContent={
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {whaleAccount13107 && isWhaleAccount && (
                  <>
                    <Stack direction="row" alignItems="center" spacing={0.25} useFlexGap>
                      <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 24 }}>
                        <CheckMark />
                        <CheckMark className={classes.checkMark} />
                      </Stack>
                      <Typography className={clsx(classes.descriptionText)} fontSize={12} ml={1}>
                        {t('wallet:payByCard.addCardModal.MITEnabled')}
                        <button onClick={onMITExplanation} type="button" className={classes.learnMore}>
                          {t('wallet:payByCard.addCardModal.learnMore')}
                        </button>
                      </Typography>
                    </Stack>

                    {haveNoMITCard && (
                      <Stack direction="row" alignItems="center" spacing={0.25} useFlexGap>
                        <ErrorOutlineIcon />
                        <Typography className={clsx(classes.descriptionText)} fontSize={12} ml={1}>
                          {t('wallet:payByCard.addCardModal.saveAsDefaultCard')}
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
              </>
            }
          />
        </Stack>
      </DialogContentBorderTopOnly>
      <DialogActions className={classes.buttonContainer}>
        <LoadingButton
          className={classes.payNowButton}
          color="primary"
          variant="contained"
          size="large"
          loading={isSubmitting}
          onClick={() => setIsSubmitting(true)}
          disabled={loadingAddress || !!addressError || isNull(address) || isSubmitting || !isFormValid}
        >
          {t('common:confirm')}
        </LoadingButton>
      </DialogActions>
    </div>
  );
}
