/* eslint-disable no-alert */
import React, { useEffect, useMemo } from 'react';
import { GraphQLError } from 'graphql';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from '@cultwines/zellar-client-sdk';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import WineCard from '../../components/WineCard';

import PlaceholderImage from '../../assets/images/stub-wine-image.png';
import ErrorPlaceholder from '../../components/ErrorPlaceholder';
import { ViewType } from '../../components/Table/Toolbar';
import useMediaQuery from '../../hooks/useMediaQuery';
import { selectSortOptions, selectTableData, TableData } from '../Discover/components/ActiveMarkets/selectors';

import CalculationToolTip from '../../components/CalculationToolTip';
import Table from '../../components/Table';
import { useActiveMarketAssets } from '../../hooks/useActiveMarketAssets';
import getImageUrl from '../../utils/getImageUrl';
import WineCardSkeleton from '../../components/WineCardSkeleton';
import { uuid } from '../../utils/uuid';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';

import { selectErrorMessage } from '../../graphql/selectors/selectErrorMessage';
import useTableControls from '../../hooks/useTableControls';
import { logError } from '../../utils/logger';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { TableRefactorFlagSet } from '../../types/FeatureFlags';
import ActiveMarketsTable from '../Discover/components/ActiveMarkets/Table';
import { isLoggedInVar } from '../../graphql/cache';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { setFiltersAndSort, setUrlFilterParams, SORT_OVERRIDES } from '../Discover/components/ActiveMarkets/helper';
import ServersFilter, {
  ServerSideFilterConfigType,
  ServerSideFiltersType,
  ServerSideFilterViewRef,
} from '../../components/Filters/serversFilter';
import { useGetActiveMarketFilters } from '../../hooks/useGetActiveMarketFilters';
import {
  ActiveMarketsFacets,
  ActiveMarketSortObject,
  BidsAndOffersEnum,
  Modal,
  PriceRangeEnum,
} from '../Discover/components/ActiveMarkets/types';
import { Headers, SortDirection } from '../../components/Table/types';
import { SelectedRowsProvider } from '../../context/selectedRowsContext';
import { UrlPath } from '../../types/Enums';
import { useQueryParameters } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    gap: theme.spacing(4),
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  card: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: `calc(50% - ${theme.spacing(2.2)})`,
    },

    [theme.breakpoints.up('xl')]: {
      width: `calc(33% - ${theme.spacing(2.2)})`,
    },
  },
}));

const ACTIVE_MARKET_PAGE_SIZE = 30;

function ActiveMarketsIframe() {
  const filterViewRef = React.useRef<ServerSideFilterViewRef>(null);
  const { activeMarketWineNameFilter, clickableTradeTiles13816 } = useFlags();
  const { tableRefactor } = useFlags<{ tableRefactor?: TableRefactorFlagSet }>();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = useQueryParameters();
  const limit = queryParams.get('limit');
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const [searchtext] = React.useState<string | undefined>('');
  const [from] = React.useState(0);
  const [viewType] = React.useState<ViewType>('card');

  const { handleSortUpdated, sortFacet, sortDirection, setSortFacet, setSortDirection } =
    useTableControls<ActiveMarketSortObject>({
      defaultFacet: 'LatestOffers',
    });

  const isLoggedIn = useReactiveVar(isLoggedInVar);

  const [filterConfig, setFilterConfig] = React.useState<ServerSideFilterConfigType>({
    paramsCheckRan: false,
    isFiltered: false,
    showSortResult: false,
    filters: {},
  });

  const { results: resultsFilters, loading: loadingFilters } = useGetActiveMarketFilters({
    isLoggedIn,
    filterConfig,
    wineName: searchtext,
  });

  const {
    error,
    loading,
    results: assets,
  } = useActiveMarketAssets({
    from,
    pageSize: Number(limit ?? ACTIVE_MARKET_PAGE_SIZE),
    sortBy: sortFacet,
    sortDirection,
    wineName: searchtext,
    isLoggedIn,
    filterConfig,
  });

  const sortOptions = React.useMemo(() => selectSortOptions('asc', sortFacet, t), [sortFacet, t]);

  const [headersConfig, setHeadersConfig] = React.useState<
    Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>
  >({
    wineName: {},
    region: {},
    vintage: {},
    unitSize: {},
    marketValue: {},
    highestBid: {},
    lowestOffer: {},
    spread: {},
  });

  const sortItems = React.useCallback(
    <T,>(direction: SortDirection, prop: keyof T) => {
      if (loading) return;
      const key = prop as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>;
      const opt = sortOptions.find((sO) => sO.key.toUpperCase() === String(key).toUpperCase());

      if (isUndefined(opt)) {
        return;
      }

      Object.keys(headersConfig).forEach((x) => {
        if (x !== key)
          setHeadersConfig((prev) => ({
            ...prev,
            [x]: { ...prev[x as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>], active: false },
          }));
      });

      setHeadersConfig((prev) => ({
        ...prev,
        [prop]: { ...prev[key], direction, active: true },
      }));

      if (SORT_OVERRIDES.find((x) => x.sortKey === opt.key)) {
        setFilterConfig((prev) => ({
          ...prev,
          showSortResult: true,
        }));
      } else {
        setFilterConfig((prev) => ({
          ...prev,
          showSortResult: false,
        }));
      }

      handleSortUpdated(opt.key, direction);
    },
    [sortOptions, handleSortUpdated, headersConfig, loading, setFilterConfig],
  );

  const headers = useMemo((): Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets> => {
    const wineClassName = 'tw-bg-white  tw-min-w-[500px]';
    const defaultClassName = 'tw-bg-white tw-w-[110px] tw-min-w-[110px] tw-max-[110px] tw-whitespace-nowrap';
    const sxStyle = { border: '1px solid #00000033', color: '#8A809A' };
    return {
      region: {
        ...headersConfig?.region,
        className: defaultClassName,
        sx: sxStyle,
        title: t('common:region').toUpperCase(),
        id: 'region',
        onClick: (id, direction) => {
          sortItems(direction, 'region');
        },
      },
      wineName: {
        ...headersConfig?.wineName,
        sx: sxStyle,
        className: wineClassName,
        title: t('market:table.wineName').toUpperCase(),
        id: 'name',
        onClick: (id, direction) => {
          sortItems(direction, 'wineName');
        },
      },

      vintage: {
        ...headersConfig?.vintage,
        className: defaultClassName,
        sx: sxStyle,
        title: t('common:vintage').toUpperCase(),
        id: 'vintage',
        onClick: (id, direction) => {
          sortItems(direction, 'vintage');
        },
      },
      unitSize: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('common:unitSize').toUpperCase(),
        id: 'unitSize',
        ...headersConfig?.unitSize,
        onClick: (id, direction) => {
          sortItems(direction, 'unitSize');
        },
      },
      marketValue: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:marketValue').toUpperCase(),
        id: 'marketValue',
        extraElement: <CalculationToolTip title={t('market:marketValueCalculation')} />,
        ...headersConfig?.marketValue,
        onClick: (id, direction) => {
          sortItems(direction, 'marketValue');
        },
      },
      highestBid: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:trading.highestBid').toUpperCase(),
        id: 'highestBid',
        ...headersConfig?.highestBid,
        onClick: (id, direction) => {
          sortItems(direction, 'highestBid');
        },
      },
      lowestOffer: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:trading.lowestOffer').toUpperCase(),
        id: 'lowestOffer',
        ...headersConfig?.lowestOffer,
        onClick: (id, direction) => {
          sortItems(direction, 'lowestOffer');
        },
      },
      spread: {
        className: defaultClassName,
        sx: sxStyle,
        title: t('product:trading.spread').toUpperCase(),
        id: 'spread',
        ...headersConfig?.spread,
        onClick: (id, direction) => {
          sortItems(direction, 'spread');
        },
      },
      actions: {
        sx: sxStyle,
        title: t('common:action').toUpperCase(),
        className: defaultClassName,
      },
    };
  }, [headersConfig, t, sortItems]);

  React.useEffect(() => {
    if (error) {
      logError({
        error: new Error('Failed to load active market assets'),
        originalError: error,
        filename: 'src/views/Discover/components/ActiveMarkets',
        additionalInfo: {
          searchtext: searchtext ?? '',
          sortDirection,
          sortFacet,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (filterConfig.paramsCheckRan || !sortOptions) return;
    setFiltersAndSort({ filterViewRef, setFilterConfig, setSortDirection, setSortFacet, sortOptions });
  }, [filterViewRef, sortOptions, setSortFacet, setSortDirection, setFilterConfig, filterConfig]);

  // function handleToolbarSortChanged(id: string): void {
  //   const opt = sortOptions.find((sO) => sO.id === id);

  //   if (isUndefined(opt)) {
  //     return;
  //   }

  //   const key = opt.filterKey as keyof ActiveMarketExtended;

  //   Object.keys(headersConfig).forEach((x) => {
  //     if (x !== key)
  //       setHeadersConfig((prev) => ({
  //         ...prev,
  //         [x]: { ...prev[x as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>], active: false },
  //       }));
  //   });

  //   setHeadersConfig((prev) => ({
  //     ...prev,
  //     [key]: {
  //       ...prev[key as keyof Headers<Omit<TableData, 'rowId'>, keyof ActiveMarketsFacets>],
  //       direction: opt.direction,
  //       active: true,
  //     },
  //   }));

  //   if (SORT_OVERRIDES.find((x) => x.sortKey === opt.key)) {
  //     setFilterConfig((prev) => ({
  //       ...prev,
  //       showSortResult: true,
  //     }));
  //   } else {
  //     setFilterConfig((prev) => ({
  //       ...prev,
  //       showSortResult: false,
  //     }));
  //   }

  //   handleSortUpdated(opt.key, opt.direction);
  // }

  function handleAddToWatchlist() {
    enqueueSnackbar(t('product:watchList.addedMessage'), {
      variant: 'info',
    });
  }

  function handleRemoveFromWatchlist() {
    enqueueSnackbar(t('product:watchList.deletedMessage'), {
      variant: 'info',
    });
  }

  function handleWatchlistError(errors: readonly GraphQLError[]) {
    enqueueSnackbar(selectErrorMessage(errors), {
      variant: 'error',
    });
  }

  function handleOpenBuyNowModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.BuyNow}&assetId=${rowId}`);
  }

  function handleOpenBidModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.Bid}&assetId=${rowId}`);
  }

  function handleOpenOfferModalClicked(rowId: string | number) {
    history.push(`${history.location.pathname}?modal=${Modal.Offer}&assetId=${rowId}`);
  }

  function handleSignInClick(): void {
    history.push(`/login`);
  }

  const tableData = selectTableData({
    rawData: assets,
    handleAddToWatchlist,
    handleOpenBidModalClicked,
    handleOpenBuyNowModalClicked,
    handleOpenOfferModalClicked,
    handleRemoveFromWatchlist,
    handleWatchlistError,
    clickableTradeTiles13816,
    isLoggedIn,
    handleSignInClick,
  });

  if (!loading && assets.length === 0 && !activeMarketWineNameFilter && !searchtext?.length) {
    return null;
  }

  const TableComponent = tableRefactor?.activeMarkets ? (
    <ActiveMarketsTable
      assets={assets}
      loading={loading}
      error={error ? t('common:somethingWentWrong') : undefined}
      onAddToWatchlist={handleAddToWatchlist}
      onRemoveFromWatchlist={handleRemoveFromWatchlist}
      onWatchlistError={handleWatchlistError}
      onBuyNowClicked={handleOpenBuyNowModalClicked}
      onBidClicked={handleOpenBidModalClicked}
      onOfferClicked={handleOpenOfferModalClicked}
    />
  ) : (
    <Table
      data={tableData}
      headers={headers}
      error={error ? t('common:somethingWentWrong') : null}
      loading={loading}
      emptyTableText={t('discover:activeMarkets.noResultsNoFilter')}
      actionCellSx={{ border: '1px solid #00000033' }}
    />
  );

  return (
    <div>
      <SelectedRowsProvider defaultState={{ maxRows: 8 }}>
        <ServersFilter
          isVisible={false}
          ref={filterViewRef}
          isLoading={loadingFilters}
          customFilters={{
            region: {
              label: t('common:filters.dropDownFilters.title.region'),
              selectAllText: t('common:filters.dropDownFilters.value.region'),
              selected: [],
              previewSelected: [],
              isSearchable: true,
            },

            producer: {
              label: t('common:filters.dropDownFilters.title.producer'),
              selectAllText: t('common:filters.dropDownFilters.value.producer'),
              selected: [],
              previewSelected: [],
              isSearchable: true,
            },

            vintage: {
              label: t('common:filters.dropDownFilters.title.vintage'),
              selectAllText: t('common:filters.dropDownFilters.value.vintage'),
              selected: [],
              previewSelected: [],
              isNumerical: true,
              isSearchable: true,
            },

            unitSize: {
              label: t('common:filters.dropDownFilters.title.size'),
              selectAllText: t('common:filters.dropDownFilters.value.size'),
              selected: [],
              previewSelected: [],
              isNumerical: true,
              isAsc: true,
            },

            score: {
              label: t('common:filters.dropDownFilters.title.score'),
              selectAllText: t('common:filters.dropDownFilters.value.score'),
              selected: [],
              previewSelected: [],
              isNumerical: true,
            },

            price: {
              label: t('common:filters.dropDownFilters.title.price'),
              selectAllText: t('common:filters.dropDownFilters.value.price'),
              selected: [],
              previewSelected: [],
              isAsc: true,
              customSortOrder: [
                PriceRangeEnum.Under500,
                PriceRangeEnum.Under1000,
                PriceRangeEnum.Range_1k_2k,
                PriceRangeEnum.Range_2k_5k,
                PriceRangeEnum.Range_5k_10k,
                PriceRangeEnum.Range_10k_Plus,
              ],
              customItems: [
                PriceRangeEnum.Under500,
                PriceRangeEnum.Under1000,
                PriceRangeEnum.Range_1k_2k,
                PriceRangeEnum.Range_2k_5k,
                PriceRangeEnum.Range_5k_10k,
                PriceRangeEnum.Range_10k_Plus,
              ],
            },
            bidsAndOffers: {
              label: t('common:filters.dropDownFilters.title.bidsAndOffers'),
              selectAllText: t('common:filters.dropDownFilters.value.bidsAndOffers'),
              selected: [],
              previewSelected: [],
              isAsc: true,
              customItems: [BidsAndOffersEnum.all_wines_with_a_offer, BidsAndOffersEnum.all_wines_with_a_bid],
              onShowResults: (filters) => {
                const selected = filters?.bidsAndOffers?.selected;
                if (selected?.includes(BidsAndOffersEnum.all_wines_with_a_offer)) {
                  setSortFacet('BestOffers');
                } else {
                  setSortFacet('BestBids');
                }
              },
            },
            ...filterConfig.filters,
          }}
          sourceData={resultsFilters}
          setFilteredSource={(isFiltered, filters) => {
            setUrlFilterParams(filters);
            setFilterConfig((prev) => ({
              ...prev,
              isFiltered: isFiltered,
              filters: filters as unknown as ServerSideFiltersType,
            }));

            Object.keys(filters).forEach((key) => {
              if (filters[key].selected.length > 0) {
                filters?.[key]?.onShowResults?.(filters);
              }
            });
          }}
        />

        {viewType === 'table' && !lessThanMd ? (
          TableComponent
        ) : (
          <div className={classes.cards}>
            {loading &&
              !assets.length &&
              !error &&
              new Array(ACTIVE_MARKET_PAGE_SIZE)
                .fill(0)
                .map(() => <WineCardSkeleton className={classes.card} key={uuid()} />)}
            {error && <ErrorPlaceholder error={t('common:somethingWentWrong')} />}
            {!assets.length && activeMarketWineNameFilter && !searchtext?.length && !error && !loading ? (
              <EmptyPlaceholder label={t('discover:activeMarkets.noResultsNoFilter')} />
            ) : (
              <>
                {assets.map((a) => {
                  const tradeTileVariant = clickableTradeTiles13816 ? 'basic' : 'date';

                  return (
                    <WineCard
                      isClickOveride
                      handleClickOverRide={() => {
                        window.parent.postMessage({ url: `${UrlPath.PRODUCT_PAGE}/${a.productUrn}` }, '*');
                      }}
                      productUrn={a.productUrn ?? ''}
                      key={a.assetId}
                      className={classes.card}
                      assetId={a.assetId}
                      cardId={a.assetId.toString()}
                      imageUrl={a.imageFileName ? getImageUrl(a.imageFileName, { height: 400 }) : PlaceholderImage}
                      region={a.region}
                      score={a.score ?? null}
                      vintage={a.vintage}
                      wineName={a.wineName}
                      spread={a.spread}
                      lowestOffer={a.lowestOffer.price}
                      highestBid={a.highestBid.price}
                      marketValue={a.marketValue}
                      tradeTileVariant={tradeTileVariant}
                      offerCreatedDate={
                        clickableTradeTiles13816 || isNullOrUndefined(a.lowestOffer.createdDate)
                          ? undefined
                          : new Date(a.lowestOffer.createdDate).toLocaleDateString()
                      }
                      bidCreatedDate={
                        clickableTradeTiles13816 || isNullOrUndefined(a.highestBid.createdDate)
                          ? undefined
                          : new Date(a.highestBid.createdDate).toLocaleDateString()
                      }
                      actions={{
                        watchlist: {
                          onAdd: handleAddToWatchlist,
                          onRemove: handleRemoveFromWatchlist,
                          onError: handleWatchlistError,
                        },
                        trade: {
                          assetId: a.assetId,
                          condensed: true,
                          onBidClicked: handleOpenBidModalClicked,
                          onBuyClicked: handleOpenBuyNowModalClicked,
                          onOfferClicked: handleOpenOfferModalClicked,
                        },
                      }}
                      unitCount={a.unitCount}
                      unitSize={a.unitSize}
                      isLogin={false}
                    />
                  );
                })}
              </>
            )}
          </div>
        )}
      </SelectedRowsProvider>
    </div>
  );
}
export default ActiveMarketsIframe;
