import makeStyles from '@mui/styles/makeStyles';
import { colours } from '../../theme/light/palette';

export const useStyles = makeStyles((theme) => ({
  // util
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  columnBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  textBold: {
    fontWeight: 'bold',
  },

  // modal
  modalContainer: {
    '@media (min-width: 768px)': {
      width: '420px',
    },
  },
  dialogTitle: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  iconTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    textTransform: 'capitalize',
  },
  cardIcon: {
    stroke: theme.palette.textPrimary,
  },
  modalContent: {
    padding: 8,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    background: theme.palette.grey[50],
  },

  // details
  expandedContentContainer: {
    padding: 8,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    background: theme.palette.grey[50],
  },
  title: {
    fontWeight: 600,
    fontSize: '14px',
    color: '#1D084F',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  differentCardBtn: {
    color: theme.palette.textPrimary,
    border: `1px solid ${theme.palette.card.background.dark}`,
    padding: '10px 20px',
    borderRadius: '60px',
    textAlign: 'center',
    fontWeight: theme.typography.label2Active.fontWeight,
    lineHeight: theme.typography.label2Active.lineHeight,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textTransform: 'capitalize',
  },
  payNowButton: {
    marginTop: '12px',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  mit: {
    fontStyle: 'italic',
    textTransform: 'capitalize',
    lineHeight: '0.75rem',
  },

  // form
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  paymentMethod: {
    gap: theme.spacing(2),
  },
  frameForm: {
    height: '47px',
  },
  storeCardDescriptionText: {
    color: theme.palette.textPrimary,
    fontSize: '12px',
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  rowGap1: {
    rowGap: theme.spacing(1),
  },
  footer: {
    marginTop: 12,
  },
  checkboxWrapper: {
    display: 'flex',
    gap: theme.spacing(0.25),
    alignItems: 'center',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  pill: {
    width: 'fit-content',
    textTransform: 'uppercase',
    padding: '3px 6px',
    borderRadius: '4px',
    backgroundColor: colours.mutedGreen,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: 'bold',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  learnMore: {
    color: 'black',
    background: 'inherit',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '12px',
    fontFamily: 'Lato',
    cursor: 'pointer',
  },
  explanationContent: {
    padding: 15,
    gap: theme.spacing(6),
  },
  exampleContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  exampleImage: {
    maxWidth: '100%',
  },
  buttonContainer: {
    background: theme.palette.grey[50],
  },
}));
