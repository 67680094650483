export enum UrlPath {
  PRODUCT_PAGE = '/product',
  LOGIN = '/login',
  REGISTER = '/register',
  DISCOVER = '/discover',
  PAGE_NOT_FOUND = '/page-not-found',
  DISCOVER_IFRAME = '/discover-iframe',
}

export enum ActionEventType {
  SCROLL_TO_EVENT = 'appLayout/scrollTo',
  UPDATE_STATE = 'appContext/updateState',
  SEARCH_AND_FILTERS = 'appContext/searchAndFilters',
}
