import React, { useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import clsx from 'clsx';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as CheckMark } from '../../assets/icons/checkmark-yellow.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { AccountType } from '../../types/AccountType';
import { colours } from '../../theme/light/palette';
import useUserDetails from '../../hooks/useUserDetails';
import Typography from '../Typography';
import MitExplanation from '../TopUpModal/MitExplanation';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    '@media (min-width: 768px)': {
      width: '525px',
    },
  },
  cardButton: {
    width: '100%',
    background: 'white',
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: 4,
    padding: theme.spacing(3),
  },
  pill: {
    width: 'fit-content',
    textTransform: 'uppercase',
    padding: '3px 6px',
    borderRadius: '4px',
    backgroundColor: colours.mutedGreen,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  cardIcon: {
    stroke: theme.palette.textPrimary,
  },
  expandIcon: {
    color: theme.palette.textInformation,
    marginLeft: 'auto',
  },
  descriptionText: {
    color: theme.palette.textPrimary,
  },
  learnMore: {
    color: 'black',
    background: 'inherit',
    border: 'none',
    textDecoration: 'underline',
    fontSize: '12px',
    fontFamily: 'Lato',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  checkMark: {
    marginLeft: -4,
  },
}));

const MitLearnMore = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { whaleAccount13107 } = useFlags();
  const { userDetails } = useUserDetails();
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const isWhaleAccount = userDetails?.accountType === AccountType.Whale;
  const [mitExplanationOpen, setMitExplanationOpen] = useState(false);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {whaleAccount13107 && isWhaleAccount && (
        <>
          <Stack direction="row" alignItems="center" spacing={0.25} useFlexGap>
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: 24 }}>
              <CheckMark />
              <CheckMark className={classes.checkMark} />
            </Stack>
            <Typography className={clsx(classes.descriptionText)} fontSize={12} ml={1}>
              {t('wallet:payByCard.addCardModal.MITEnabled')}
              <button onClick={() => setMitExplanationOpen(true)} type="button" className={classes.learnMore}>
                {t('wallet:payByCard.addCardModal.learnMore')}
              </button>
            </Typography>
          </Stack>
          <MitExplanation
            open={mitExplanationOpen}
            onClose={() => setMitExplanationOpen(false)}
            fullScreen={lessThanMd}
          />
        </>
      )}
    </>
  );
};

export default MitLearnMore;
