import { useLocation } from 'react-router-dom';

export function useQueryParameters(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

interface QueryParams {
  [key: string]: string;
}

export const getAllQueryParams = (): QueryParams => {
  const params = new URLSearchParams(window.location.search);
  const queryObject: QueryParams = {};
  params.forEach((value, key) => {
    queryObject[key] = decodeURIComponent(value);
  });
  return queryObject;
};
