import PlaceholderImage from '../../assets/images/stub-wine-image.png';
import { DrinkingWindowAdvice } from '../../components/DrinkingWindowChip';
import getImageUrl from '../../utils/getImageUrl';
import { isNull } from '../../utils/isNull';
import { massageToNull } from '../../utils/massageToNull';
import selectDrinkingWindowAdvice from './selectDrinkingWindowAdvice';
import { ExternalPortfolioQuery } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';

export type ExternalPortfolioItem = {
  id: number;
  name: string;
  vintage: number;
  region: string;
  drinkingWindow: {
    advice: DrinkingWindowAdvice;
    dateFrom: string;
    dateTo: string;
  };
  units: number;
  unitSize: string;
  unitCount: number;
  totalPurchasePrice: number;
  totalAvgPurchasePrice: number | null;
  score: number | null;
  marketValue: number;
  totalMarketValue: number;
  performanceValueDelta: number | null;
  performancePercentageDelta: number | null;
  highestBid: number | null;
  lowestOffer: number | null;
  imageFilename: string;
};

export type ExternalPortfolioItemFacets = Omit<ExternalPortfolioItem, 'drinkingWindow'>;

export function selectExternalPortfolioItems(original: ExternalPortfolioQuery | undefined): ExternalPortfolioItem[] {
  if (!original) {
    return [];
  }

  if (!original.externalPortfolioUserAssets || original.externalPortfolioUserAssets.length === 0) {
    return [];
  }

  const normalised = original.externalPortfolioUserAssets
    .filter(
      (portfolioItem) => !isNull(portfolioItem) && !isNull(portfolioItem.asset) && !isNull(portfolioItem.asset.id),
    )
    .map((portfolioItem) => {
      if (isNull(portfolioItem)) {
        throw new Error('this can never happen as we filter out the null values in prev step.');
      }

      const ret: ExternalPortfolioItem = {
        id: portfolioItem.asset.id,
        name: portfolioItem.asset.vintage.wine.name,
        vintage: portfolioItem.asset.vintage.vintage,
        region: portfolioItem.asset.vintage.wine.region?.name || 'Unknown region',
        drinkingWindow: {
          advice: selectDrinkingWindowAdvice(portfolioItem.asset.vintage.drinkingWindow!.advice),
          dateFrom: portfolioItem.asset.vintage.drinkingWindow!.dateFrom,
          dateTo: portfolioItem.asset.vintage.drinkingWindow!.dateTo,
        },
        units: portfolioItem.quantityOwned,
        unitCount: portfolioItem.asset.unitCount,
        unitSize: portfolioItem.asset.unitSize,
        totalPurchasePrice: portfolioItem.totalPurchasePrice,
        totalAvgPurchasePrice: null,
        score: portfolioItem.calculatedMarketData.combinedScore || null,
        marketValue: portfolioItem.asset.marketValue ?? 0,
        totalMarketValue: portfolioItem.asset.marketValue
          ? portfolioItem.asset.marketValue * portfolioItem.quantityOwned
          : 0,
        performancePercentageDelta: !isNullOrUndefined(
          portfolioItem.calculatedMarketData.performance.percentageDifference,
        )
          ? parseFloat(portfolioItem.calculatedMarketData.performance.percentageDifference.toFixed(2))
          : null,
        performanceValueDelta: portfolioItem.calculatedMarketData.performance.valueDifference ?? null,
        lowestOffer: massageToNull(portfolioItem.asset.spread?.lowestOffer?.price),
        highestBid: massageToNull(portfolioItem.asset.spread?.highestBid?.price),
        imageFilename: portfolioItem.asset.vintage.imageFileName
          ? getImageUrl(portfolioItem.asset.vintage.imageFileName, { height: 200 })
          : PlaceholderImage,
      };
      return ret;
    });

  return normalised;
}
