import { ApolloError } from '@apollo/client';
import { isUndefined } from '@cultwines/zellar-client-sdk/utils/isUndefined';
import PlaceholderImage from '../../assets/images/stub-wine-image.png';
import getImageUrl from '../../utils/getImageUrl';
import { massageToNull } from '../../utils/massageToNull';
import { WatchListItem } from '../../__generated__/graphql';
import { isNullOrUndefined } from '../../utils/isNullOrUndefined';
import { getPriceRange } from '../../utils/common';

export type WatchlistFacets = Omit<WatchlistAsset, 'marketData' | 'unitSize'>;

export type WatchlistAsset = {
  assetId: number;
  name: string;
  vintage: number;
  appellation: string;
  /**
   * In the search results, we are going to get back all manner
   * of regions, even those we don't recognise within Zellar (see RegionName).
   * We still want to display the region name we are given from the search query,
   * so there is no need to map it to a 'Zellar' region at this level.
   */
  region: string;
  score: number | null;
  marketValue: number;
  unitSize: string;
  unitCount: number;
  highestBid: number | null;
  lowestOffer: number | null;
  performancePercentageDelta: number | null;
  performanceValueDelta: number | null | undefined;
  marketData: Record<string, number>;
  imageFilename: string;
  spread: number | null;
  priceRange: string | null;
  bestOffer?: number | null;
  bestBid?: number | null;
  unitCountUnitSize: string;
  productUrn?: string;
};

export function selectWatchlistItems(rawItems: WatchListItem[]): WatchlistAsset[] {
  return rawItems.map((r) => {
    const lowestOfferPrice = parseFloat((r?.asset?.spread?.lowestOffer?.price ?? 0).toFixed(4));
    const highestBidPrice = parseFloat((r?.asset?.spread?.highestBid?.price ?? 0).toFixed(4));
    const marketValue = parseFloat((r?.asset?.marketValue ?? 0).toFixed(4));
    return {
      productUrn: r?.asset?.productUrn ?? '',
      assetId: r.asset.id,
      name: r.asset.vintage.wine.name,
      vintage: r.asset.vintage.vintage,
      appellation: r.asset.vintage.wine.country ?? '',
      region: r.asset.vintage.wine.region?.name ?? 'Unknown region',
      score: r.calculatedMarketData.combinedScore || null,
      marketValue: r.asset.marketValue ?? 0,
      unitCount: r.asset.unitCount,
      unitSize: r.asset.unitSize,
      highestBid: massageToNull(r.asset.spread?.highestBid?.price),
      lowestOffer: massageToNull(r.asset.spread?.lowestOffer?.price),
      performancePercentageDelta: !isNullOrUndefined(r.calculatedMarketData.performance.percentageDifference)
        ? parseFloat(r.calculatedMarketData.performance.percentageDifference.toFixed(2))
        : null,
      performanceValueDelta: r.calculatedMarketData.performance.valueDifference,
      marketData: {},
      imageFilename: r.asset.vintage.imageFileName
        ? getImageUrl(r.asset.vintage.imageFileName, { height: 400 })
        : PlaceholderImage,
      spread: massageToNull(r.asset.spread?.percentageDifference),
      priceRange: getPriceRange(r.asset.marketValue ?? null),
      unitCountUnitSize: `${r.asset.unitCount}x${r.asset.unitSize}`,
      bestOffer: lowestOfferPrice && marketValue ? lowestOfferPrice / marketValue : 0,
      bestBid: highestBidPrice && marketValue ? highestBidPrice / marketValue : 0,
    };
  });
}

export function selectError(error: ApolloError | undefined): string | null {
  if (isUndefined(error)) {
    return null;
  }

  return error.message;
}
