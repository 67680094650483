// import { gql, useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWineScores } from '../../../hooks/useWineScores';
import Divider from '../../Divider';
import Typography from '../../Typography';
import TastingNotes from './TastingNotes';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    overflow: 'auto',
    height: 'auto',
    maxHeight: 260,
  },
  content: {
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  horizontalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hint: {
    color: theme.palette.textLabel,
  },
  scoreContainer: {
    alignItems: 'baseline',
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

interface Props {
  assetId: number;
  isLoggedIn?: boolean;
  isProductError?: boolean;
}

export default function ScoreSection({ assetId, isLoggedIn = false, isProductError }: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const { wineScores, error, loading } = useWineScores({ assetId }, isLoggedIn);
  const hasValidTastingNotes = wineScores.some((item) => item.tastingNotes !== null && item.tastingNotes !== '');

  if (loading) {
    return <Skeleton width="100%" height="120px" variant="rectangular" />;
  }

  if (error || isProductError) {
    return <Typography customVariant="subtitle1">{t('product:scoresError')}</Typography>;
  }

  if (!wineScores.length || !hasValidTastingNotes) {
    return <Typography customVariant="subtitle1">{t('product:noScores')}</Typography>;
  }

  return (
    <div className={classes.container}>
      {wineScores.map((score, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={classes.content}>
          <div className={classes.horizontalContainer}>
            <Typography customVariant="subtitle1">{score.sourceName}</Typography>
            <div className={classes.scoreContainer}>
              <Typography className={classes.hint} customVariant="subtitle1Bold">
                {t('product:score')}
              </Typography>
              <Typography customVariant="h5">{score.score}/100</Typography>
            </div>
          </div>
          <TastingNotes label={score.tastingNotes || t('product:noNotes')} />
          <Divider customVariant="primary" />
        </div>
      ))}
    </div>
  );
}
