/* eslint-disable no-nested-ternary */
import Dialog from '@mui/material/Dialog';
import React from 'react';
import { useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReviewAddress from './ReviewAddress';
import AddressModal from '../AddressModal';
import PaymentMethod from './PaymentMethod';
import BankDetail from './BankDetail';
import ConfirmSuccess from './ConfirmSuccess';
import KycPending from './KycPending';
import { getKycStatus } from '../../services/auth';
import { KycStatus } from '../../__generated__/graphql';
import useMediaQuery from '../../hooks/useMediaQuery';
import AddPaymentCardModal from './AddPaymentCard';
import MitExplanation from './MitExplanation';

const useStyles = makeStyles(() => ({
  container: {
    padding: 15,
    width: '100%',
  },
}));

interface TopUpModalProps {
  open: boolean;
  isAddNewCard?: boolean;
  isAddressForm?: boolean;
  onClose: () => void;
  customSuccessUri?: string;
}

export default function TopUpModal({
  open,
  isAddNewCard,
  onClose,
  isAddressForm,
  customSuccessUri,
}: TopUpModalProps): JSX.Element {
  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.md));
  const { billingAddressTopUpModal13811 } = useFlags();
  const initModalType = isAddressForm
    ? 'addressForm'
    : getKycStatus() === KycStatus.pending
    ? 'kycPending'
    : billingAddressTopUpModal13811
    ? isAddNewCard
      ? 'addPaymentCardForm'
      : 'paymentMethodForm'
    : 'reviewAddress';
  const [modalType, setModalType] = React.useState<
    | 'reviewAddress'
    | 'addressForm'
    | 'paymentMethodForm'
    | 'addPaymentCardForm'
    | 'mitExplanation'
    | 'bankDetails'
    | 'confirmSuccess'
    | 'kycPending'
  >(initModalType);

  const [mitExplanationOpen, setMitExplanationOpen] = React.useState(false);

  const classes = useStyles();

  function handleReviewAddressConfirm() {
    return isAddNewCard ? setModalType('addPaymentCardForm') : setModalType('paymentMethodForm');
  }

  function handlePaymentFormClose() {
    return billingAddressTopUpModal13811 ? onClose() : setModalType('reviewAddress');
  }

  return (
    <>
      <Dialog open={open} maxWidth="lg" fullScreen={lessThanMd}>
        <div className={classes.container}>
          {(() => {
            switch (modalType) {
              case 'kycPending':
                return <KycPending onClose={onClose} />;
              case 'reviewAddress':
                return (
                  <ReviewAddress
                    onClose={onClose}
                    openAddressModal={() => setModalType('addressForm')}
                    openPaymentMethodModel={handleReviewAddressConfirm}
                  />
                );
              case 'addressForm': {
                return (
                  <AddressModal
                    addressType="Billing"
                    onClose={isAddressForm ? () => handlePaymentFormClose() : () => setModalType(initModalType)}
                    open
                  />
                );
              }
              case 'paymentMethodForm': {
                return (
                  <PaymentMethod
                    onClose={handlePaymentFormClose}
                    onBankDetail={() => setModalType('bankDetails')}
                    openAddressModal={() => setModalType('addressForm')}
                  />
                );
              }
              case 'addPaymentCardForm':
                return (
                  <AddPaymentCardModal
                    onClose={handlePaymentFormClose}
                    onMITExplanation={() => setMitExplanationOpen(true)}
                    openAddressModal={() => setModalType('addressForm')}
                    customSuccessUri={customSuccessUri}
                  />
                );
              case 'bankDetails': {
                return (
                  <BankDetail
                    onClose={() => setModalType('paymentMethodForm')}
                    onConfirmSuccess={() => setModalType('confirmSuccess')}
                  />
                );
              }
              case 'confirmSuccess': {
                return <ConfirmSuccess onClose={onClose} />;
              }
              default:
                return null;
            }
          })()}
        </div>
      </Dialog>

      <MitExplanation open={mitExplanationOpen} onClose={() => setMitExplanationOpen(false)} fullScreen={lessThanMd} />
    </>
  );
}
